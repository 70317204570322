/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import DropdownContentFrame from 'components/Select/Dropdown/presets/components/DropdownContentFrame';
import React, { CSSProperties, ReactElement, Ref } from 'react';
import Dropdown from 'components/Select/Dropdown/Dropdown';
import { TextField } from 'components';

import TextFieldWithVariableHighlighting from './TextFieldWithVariableHighlighting';
import VariablesAndPlaceholders from './VariablesAndPlaceholders';
import FocussableWrapper from './FocussableWrapper';

interface TextProps {
	placeholder?: string;
	hasErrors?: boolean;
	disabled: boolean;
	value: string;
	setValue: (value: string) => void;
}

export default function Text({ value = '', setValue, ...props }: TextProps): ReactElement {
	return (
		<Input
			value={value}
			onChange={(value) => {
				setValue(value);
			}}
			{...props}
		/>
	);
}

interface InputProps {
	onChange: (value: string) => void;
	onFocus?: () => void;
	onBlur?: () => void;
	placeholder?: string;
	hasErrors?: boolean;
	sx?: CSSProperties;
	disabled: boolean;
	value: string;
}

export function Input({
	placeholder,
	hasErrors,
	disabled,
	value,
	sx,
	onChange,
	onFocus,
	onBlur,
}: InputProps): ReactElement {
	return (
		<Dropdown<string>
			placement="bottom-start"
			value={value}
			renderComponent={({ showMenu, value = '', ref, setShowMenu }) => {
				let component;
				if (!showMenu) {
					component = (
						<TextFieldWithVariableHighlighting
							value={String(value)}
							hasErrors={hasErrors}
							disabled={disabled}
							onClick={() => setShowMenu(true)}
						/>
					);
				} else {
					component = (
						<TextWithLocalState
							placeholder={placeholder}
							hasErrors={hasErrors}
							disabled={disabled}
							value={value}
							setValue={onChange}
						/>
					);
				}

				return (
					<FocussableWrapper
						ref={ref as Ref<HTMLDivElement>}
						onFocus={() => {
							setShowMenu(true);
							onFocus?.();
						}}
						onBlur={() => {
							setShowMenu(false);
							onBlur?.();
						}}
						sx={sx}
						disabled={disabled}
					>
						{component}
					</FocussableWrapper>
				);
			}}
		>
			{({ width }) => {
				return (
					<DropdownContentFrame>
						<VariablesAndPlaceholders width={width} selectItem={onChange} />
					</DropdownContentFrame>
				);
			}}
		</Dropdown>
	);
}

interface TextWithLocalStateProps {
	placeholder?: string;
	hasErrors?: boolean;
	disabled: boolean;
	value: string;
	setValue: (value: string) => void;
}

interface State {
	localValue: string;
}

class TextWithLocalState extends React.Component<TextWithLocalStateProps, State> {
	constructor(props: TextWithLocalStateProps) {
		super(props);
		this.state = { localValue: props.value };
	}

	componentWillUnmount(): void {
		this.props.setValue(this.state.localValue);
	}

	render(): ReactElement {
		return (
			<TextField
				value={this.state.localValue}
				type="text"
				onChange={(e) => this.setState({ localValue: e.target.value })}
				placeholder={this.props.placeholder}
				hasError={this.props.hasErrors}
				disabled={this.props.disabled}
				autoFocus
				height="40px"
			/>
		);
	}
}
