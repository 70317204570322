/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	ExperimentStepTargetQueryKeyAddedProperties,
	ExperimentStepTargetQueryKeyRemovedProperties,
	ExperimentStepTargetQueryKeyReplacedProperties,
	ampli,
} from 'ampli';
import {
	ActionVO,
	BaseExperimentStepVOUnion,
	ExperimentStepActionVO,
	ExperimentStepRadiusVO,
	TargetPredicateTemplateVO,
	TemplateMetadataVO,
} from 'ui-api';
import StepTargetsBlastRadius from 'pages/experiments/components/experimentStepEditAction/StepTargetsBlastRadius';
import PredicateEditor, { KeyError, ValuesError } from 'components/PredicateEditor/PredicateEditor';
import { IconChevronDoubleLeft, IconChevronDoubleRight, IconWarningOpen } from 'components/icons';
import { isOccuranceActionPredicateVO } from 'pages/templates/UseTemplateModal/types';
import { isQueryLanguagePredicateVO } from 'components/PredicateEditor/utils';
import { useBlastRadiusCount } from 'pages/experiments/components/utils';
import { useTargetDefinitions } from 'targets/useTargetDefinitions';
import { ErrorMessage, Flex } from '@steadybit/ui-components-lib';
import { useCount } from 'pages/experiments/wizard/common/utils';
import { Container, Stack, Text } from 'components';
import { useField, useFormikContext } from 'formik';
import { Target } from 'pages/components/Target';
import { ReactElement, useEffect } from 'react';
import { getLabel } from 'i18n/label';

import { getSummarizedPredicateErrorMessage } from '../useFieldErrors';
import { ExperimentError, ExperimentFormValues } from '../types';
import useIsExperimentDisabled from '../useIsExperimentDisabled';
import { useEditorSettings } from '../useEditorSettings';
import Section from './Section';

interface ActionTargetSelectionProps {
	actionStep: ExperimentStepActionVO;
	expanded: boolean;
	action: ActionVO;
	stepPath: string;
	setExpanded: (expanded: boolean) => void;
}

export default function ActionTargetSelection(props: ActionTargetSelectionProps): ReactElement | null {
	const { action, actionStep } = props;
	const targetType = action.target?.type;

	if (!targetType || !actionStep.blastRadius) {
		return null;
	}

	return <ActionTargetSelectionContent blastRadius={actionStep.blastRadius} targetType={targetType} {...props} />;
}

interface ActionTargetSelectionContentProps extends ActionTargetSelectionProps {
	blastRadius: ExperimentStepRadiusVO;
	targetType: string;
}

function ActionTargetSelectionContent({
	blastRadius,
	actionStep,
	targetType,
	expanded,
	stepPath,
	action,
	setExpanded,
}: ActionTargetSelectionContentProps): ReactElement | null {
	const disabled = useIsExperimentDisabled();
	const formik = useFormikContext<ExperimentFormValues>();
	const { environmentId, experimentKey, experimentVariables = [] } = formik.values;

	const targetDefinitions = useTargetDefinitions();
	const targetDefinition = (targetDefinitions.value || []).find((target) => target.id === targetType);
	const totalTargetCountsInEnvironment = useCount(targetDefinitions, environmentId);

	const [stepField] = useField<BaseExperimentStepVOUnion>(stepPath);
	const [metadataField] = useField<TemplateMetadataVO>('metadata');
	const [, meta] = useField<Array<KeyError | ValuesError>>(`${stepPath}.blastRadius.predicateparts`);

	const predicatePartsErrors = (meta.error || []) as Array<KeyError | ValuesError> | undefined;
	const predicateErrorSummary = getSummarizedPredicateErrorMessage(predicatePartsErrors);

	const [, predicateMeta] = useField<string>(`${stepPath}.blastRadius.predicate`);
	const predicateError = predicateMeta.error as ExperimentError | undefined;
	const predicateErrors: ExperimentError[] = [
		predicateError,
		predicateErrorSummary ? { message: predicateErrorSummary, level: 'error' } : undefined,
	].filter(Boolean) as ExperimentError[];

	const { mode: editorMode } = useEditorSettings();
	const cannotDeterminCount: boolean =
		editorMode === 'templateEditor' && // only for templates. experiments can resolve values and therefore targets
		!!blastRadius.predicate &&
		stepField &&
		predicateContainsPlaceholderOrVariable(stepField.value.id, metadataField?.value);

	const blastRadiusCount = useBlastRadiusCount(blastRadius, environmentId, experimentVariables, [expanded]);

	useEffect(() => {
		if (cannotDeterminCount && expanded) {
			setExpanded(!expanded);
		}
	}, [expanded, cannotDeterminCount]);

	return (
		<Stack size="none" bg="neutral100">
			<StepTargetsBlastRadius
				quantityRestriction={action.quantityRestriction}
				cannotDeterminCount={cannotDeterminCount}
				blastRadiusCount={blastRadiusCount}
				blastRadius={blastRadius}
				actionKind={action.kind}
				targetType={targetType}
				stepPath={stepPath}
				disabled={disabled}
			/>
			<Section
				title={
					targetDefinition ? (
						<Flex direction="horizontal" align="center" spacing="xSmall">
							<Text variant="smallStrong" color="neutral800">
								Target Selection for:
							</Text>
							<Target targetDefinitions={[targetDefinition]} targetId={targetDefinition.id} />
						</Flex>
					) : (
						'Target Selection'
					)
				}
			>
				<Stack size="xSmall" width="100%">
					{editorMode === 'experiment' &&
						totalTargetCountsInEnvironment.value?.[targetType] === 0 &&
						targetDefinition && (
							<Stack direction="horizontal" alignItems="center" size="xxSmall" pb="small">
								<IconWarningOpen color="coral" />
								<Text variant="small" color="neutral600">
									No {getLabel(targetDefinition.label, 0)} can be found in the selected environment.
								</Text>
							</Stack>
						)}

					<Container>
						<PredicateEditor
							hasErrors={predicateErrors.filter((err) => err.level === 'error').length > 0}
							partErrors={predicatePartsErrors}
							additionalActions={
								<Text
									variant="mediumStrong"
									sx={{
										color: cannotDeterminCount ? 'neutral400' : 'primary',
										cursor: cannotDeterminCount ? 'default' : 'pointer',
									}}
									onClick={
										cannotDeterminCount
											? undefined
											: () => {
													setExpanded(!expanded);
													ampli.experimentTargetsAttackedViewed({
														attack_query_defined: !!blastRadius.predicate,
														target_type: targetType,
													});
												}
									}
								>
									{expanded ? 'Hide' : 'Show'} Targets
									{!cannotDeterminCount && blastRadiusCount.total && blastRadiusCount.total >= 0
										? ` (${blastRadiusCount.total})`
										: ''}
									{expanded ? <IconChevronDoubleLeft ml="xxxSmall" /> : <IconChevronDoubleRight ml="xxxSmall" />}
								</Text>
							}
							disabled={disabled}
							predicate={blastRadius.predicate}
							setPredicate={(value) => {
								// if the predicate is a query language predicate and has no query, we need to set it to a predicate so it will fetch an empty result
								if (value && isQueryLanguagePredicateVO(value) && !value.query) {
									formik.setFieldValue(`${stepPath}.blastRadius.predicate`, {
										type: 'predicate',
									});
									formik.setFieldTouched(`${stepPath}.blastRadius.predicate`, true);
								} else {
									formik.setFieldValue(`${stepPath}.blastRadius.predicate`, value);
									formik.setFieldTouched(`${stepPath}.blastRadius.predicate`, true);
								}
							}}
							targetType={targetType}
							environmentId={environmentId}
							targetPredicateTemplates={action.targetPredicateTemplates || []}
							onTargetPredicateTemplateApplied={(template: TargetPredicateTemplateVO) =>
								ampli.experimentStepTargetSelectionTemplateApplied({
									action: actionStep.actionId,
									environment_id: environmentId,
									experiment_step_target_template_name: template.name,
									experiment_step_target_template_method: 'ui',
									experiment_key: experimentKey,
								})
							}
							onAttributeKeyChange={(key: string, change: 'added' | 'removed' | 'replaced') => {
								const targetQueryKeyChangeProps:
									| ExperimentStepTargetQueryKeyAddedProperties
									| ExperimentStepTargetQueryKeyRemovedProperties
									| ExperimentStepTargetQueryKeyReplacedProperties = {
									action: actionStep.actionId,
									environment_id: environmentId,
									experiment_key: experimentKey,
									experiment_step_target_key_changed: key,
								};
								if (change === 'added') {
									ampli.experimentStepTargetQueryKeyAdded({ ...targetQueryKeyChangeProps });
								} else if (change === 'removed') {
									ampli.experimentStepTargetQueryKeyRemoved({ ...targetQueryKeyChangeProps });
								} else if (change === 'replaced') {
									ampli.experimentStepTargetQueryKeyReplaced({ ...targetQueryKeyChangeProps });
								}
							}}
						/>
					</Container>

					{predicateErrors.length > 0 && (
						<Flex spacing="xxSmall">
							{predicateErrors.map(({ message, level }, index) => (
								<ErrorMessage key={index} level={level} type="small" withIcon>
									{message}
								</ErrorMessage>
							))}
						</Flex>
					)}
				</Stack>
			</Section>
		</Stack>
	);
}

function predicateContainsPlaceholderOrVariable(stepId: string, metadata?: TemplateMetadataVO): boolean {
	if (!metadata) {
		return true;
	}

	return (
		Object.values(metadata.variables)
			.flat()
			.concat(Object.values(metadata.placeholders || {}).flat())
			.filter(isOccuranceActionPredicateVO)
			.filter((occurance) => occurance.stepId === stepId).length > 0
	);
}
