/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	CreateExperimentRequest,
	ExperimentStepActionVO,
	ExperimentStepWaitVO,
	ExperimentVO,
	TrackingCreationMethodVO,
	VariableVO,
} from 'ui-api';
import { ExperimentFormActions } from 'pages/experimentsV2/types';
import { Services } from 'services/services';
import { track } from 'tracking/sentry';
import { FormikErrors } from 'formik';
import { ensure } from 'utils/ensure';
import { v4 as uuidv4 } from 'uuid';

import { DuplicateExperimentResult } from './components/DuplicateExperimentConfirm';

export type PreparedFormData = {
	initialValues: ExperimentFormValues;
	initialErrors: FormikErrors<ExperimentFormValues>;
	actions: ExperimentFormActions[];
	experiment?: ExperimentVO;
	isNotFound?: boolean;
};

export type ExperimentFormValues = CreateExperimentRequest & {
	action?: ExperimentFormActions;
	variables: VariableVO[];
	experimentKey?: string;
	version?: number;

	// This is a synthetic, unused field which is only added to redirect errors to the correct field
	overallExperimentVariables?: string;
};

export const InitialExperimentFormValues: ExperimentFormValues = {
	name: '',
	templateId: '',
	templateTitle: '',
	teamId: '',
	externalId: '',
	environmentId: '',
	hypothesis: '',
	webhookIds: [],
	tags: [],
	lanes: [
		{
			id: '0',
			steps: [],
		},
	],
	// variables is deprecated on the experiment but we fetch it from the env and add it to the form
	variables: [],
	experimentVariables: [],
	creationMethod: 'UI_FROM_SCRATCH',
};

export const prepareCreateExperimentForm = async (teamId: string, templateId?: string): Promise<PreparedFormData> => {
	const initialValues = templateId
		? await Services.legacyExperimentTemplates.instantiateTemplate(templateId)
		: InitialExperimentFormValues;
	return { initialValues: { ...initialValues, teamId, variables: [] }, initialErrors: {}, actions: ['save'] };
};

export const prepareCopyExperimentForm = async (
	experimentKey: string,
	duplicateResult: DuplicateExperimentResult,
	trackingCreationMethod: TrackingCreationMethodVO,
): Promise<PreparedFormData> => {
	try {
		const original = await Services.experiments.fetchExperiment(ensure(experimentKey));
		const variables = original.environmentId
			? duplicateResult.environmentId
				? await Services.environments.fetchEnvironmentVariables(duplicateResult.environmentId)
				: []
			: [];

		return {
			initialValues: {
				name: duplicateResult.name || `Copy of ${original.name}`,
				templateId: '',
				templateTitle: '',
				externalId: '',
				hypothesis: duplicateResult.hypothesis || '',
				tags: original.tags,
				teamId: duplicateResult.teamId || original.teamId,
				environmentId: duplicateResult.environmentId || original.environmentId,
				lanes: original.lanes.map((lane) => ({
					id: lane.id,
					steps: lane.steps.map((step) => {
						const newStep = {
							id: uuidv4(),
							type: step.type,
							ignoreFailure: step.ignoreFailure,
							parameters: step.parameters,
							customLabel: step.customLabel,
							metricQueries: step.metricQueries,
							metricChecks: step.metricChecks,
						};
						if (step.type === 'action') {
							const action = step as ExperimentStepActionVO;
							return {
								...newStep,
								actionId: action.actionId,
								blastRadius: {
									...action.blastRadius,
									...(duplicateResult.clearTargets && { selection: undefined, predicate: undefined }),
								},
							} as ExperimentStepActionVO;
						} else return { ...newStep } as ExperimentStepWaitVO;
					}),
				})),
				webhookIds: [...original.webhookIds],
				variables,
				experimentVariables: duplicateResult.clearTargets ? [] : original.experimentVariables,
				creationMethod: trackingCreationMethod,
			},
			initialErrors: {},
			actions: ['save'],
		};
	} catch (error) {
		track('Could not copy experiment', { error });
		console.warn('Could not copy experiment', error);
		return { initialValues: InitialExperimentFormValues, initialErrors: {}, actions: ['save'] };
	}
};
