/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import {
	AddTeamMembersRequest,
	CreateTeamRequest,
	GetTeamsPageResponse,
	GetTeamSummariesResponse,
	TeamSummaryVO,
	TeamVO,
	UpdateTeamRequest,
	ViolationVO,
} from 'ui-api';
import { AsyncState, useAsyncState } from 'utils/hooks/useAsyncState';
import { PageParams } from 'utils/hooks/usePage';
import { remove, set } from 'utils/localStorage';
import { useMemo } from 'react';
import axios from 'axios';

import { Services } from './services';

export function useFetchTeamsIncluding(
	query: string,
	teamId?: string,
	mine?: boolean,
	page = 0,
	size = 20,
): [AsyncState<TeamSummaryVO[]>, () => void] {
	const [myTeams, fetch] = useAsyncState(() => Services.teams.findTeams(query, mine, page, size));
	const [including] = useAsyncState(
		async () => {
			if (myTeams.value && teamId && !myTeams.value.some((t) => t.id === teamId)) {
				const missing = await Services.teams.findTeamById(teamId);
				if (missing) {
					return [missing];
				}
			}
			return [];
		},
		[myTeams.value, teamId],
		[],
	);

	return useMemo(
		() => [
			{
				value: [...(myTeams.value ?? []), ...including.value],
				loading: myTeams.loading || including.loading,
				error: myTeams.error || including.error,
			},
			fetch,
		],
		[including.value, including.loading, including.error, myTeams.value, myTeams.loading, myTeams.error, fetch],
	);
}

export class TeamsApi {
	setTeam(tenant: string, teamKey: string): void {
		if (teamKey) {
			set(tenant, 'team-key', teamKey);
		} else {
			this.removeTeam(tenant);
		}
	}

	removeTeam(tenant: string): void {
		remove(tenant, 'team-key');
	}

	async fetchTeamsFirst100(): Promise<GetTeamsPageResponse> {
		return this.fetchTeams(new PageParams(0, 100, [['name']]));
	}

	async fetchTeams(page: PageParams): Promise<GetTeamsPageResponse> {
		return (await axios.get<GetTeamsPageResponse>('/ui/teams', { params: page.toUrlSearchParams() })).data;
	}

	async getAllTeams(): Promise<TeamSummaryVO[]> {
		return (
			await axios.get<GetTeamSummariesResponse>('/ui/teams', {
				params: { query: '', page: 0, size: 1000, mine: false },
			})
		).data.content;
	}

	async findTeams(query: string, mine?: boolean, page = 0, size = 20): Promise<TeamSummaryVO[]> {
		const response = await axios.get<GetTeamSummariesResponse>('/ui/teams', {
			params: { query, page, size, mine: mine ?? false },
		});
		return response.data.content;
	}

	async findInitialTeam(): Promise<TeamSummaryVO | undefined> {
		return (await this.findTeams('', true, 0, 1))[0];
	}

	async findTeamByKey(key: string): Promise<TeamSummaryVO | undefined> {
		const teams = await this.findTeams(key, false, 0, 10);
		return teams.find((team) => team.key === key);
	}

	async findTeamsById(ids: string[]): Promise<Map<string, TeamSummaryVO>> {
		if (ids.length === 0) {
			return new Map();
		}
		const params = new URLSearchParams();
		ids.forEach((id) => id && params.append('id', id));
		const teams = (await axios.get<GetTeamSummariesResponse>('/ui/teams', { params })).data.content;
		const result = new Map<string, TeamSummaryVO>();
		teams.forEach((t) => result.set(t.id, t));
		return result;
	}

	async findTeamById(id: string): Promise<TeamSummaryVO | undefined> {
		return (await this.findTeamsById([id])).get(id);
	}

	async isUserInTeam(id: string): Promise<boolean> {
		const teamsOfUser = await this.findTeams('', true, 0, 100);
		return !!teamsOfUser.find((team) => team.id === id);
	}

	async createTeam(body: CreateTeamRequest): Promise<TeamVO> {
		return (await axios.post<TeamVO>('/ui/teams', body)).data;
	}

	async validateTeam(body: CreateTeamRequest): Promise<TeamVO> {
		return (await axios.post<TeamVO>('/ui/teams/validate', body)).data;
	}

	async validateTeamMembers(id: string, body: string[]): Promise<ViolationVO[]> {
		return (await axios.post<ViolationVO[]>(`/ui/teams/${id}/members/validate`, body)).data;
	}

	async fetchTeam(id: string): Promise<TeamVO> {
		return (await axios.get<TeamVO>(`/ui/teams/${id}`)).data;
	}

	async deleteTeam(id: string): Promise<void> {
		await axios.delete(`/ui/teams/${id}`);
	}

	async updateTeam(id: string, body: UpdateTeamRequest): Promise<void> {
		await axios.post(`/ui/teams/${id}`, body);
	}

	async setTeamMembers(id: string, body: AddTeamMembersRequest): Promise<void> {
		await axios.post(`/ui/teams/${id}/members`, body);
	}

	async getPermissions(): Promise<string[]> {
		return (await axios.get('/ui/teams/permissions')).data;
	}
}
