/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { Container, Stack, Text, TextField, Tooltip } from 'components';
import { ReactElement, useEffect, useState } from 'react';
import { ButtonIcon } from 'components/ButtonIcon';
import { IconEdit } from 'components/icons';

interface EditableLabelProps {
	value: string;
	placeholder?: string;
	onChange?: (value: string) => void;
	hasError?: boolean;
	disabled?: boolean;
	theme?: 'light' | 'dark' | 'slate';
	width?: string | number;
	minWidth?: string | number;
	maxWidth?: string | number;
	maxLength?: number | null;
	withTooltip?: boolean;
	/**
	 * @deprecated Dont use this. This is only for the storybook
	 */
	isInitialEditing?: boolean;
}

export default function EditableLabel({
	value,
	theme = 'dark',
	hasError,
	placeholder = 'Untitled',
	disabled,
	onChange,
	width,
	withTooltip,
	minWidth,
	maxWidth,
	maxLength,
	isInitialEditing,
}: EditableLabelProps): ReactElement {
	const [lokalValue, setLokalValue] = useState(value);
	useEffect(() => {
		setLokalValue(value);
	}, [value]);

	const color = theme === 'light' ? 'neutral000' : theme === 'dark' ? 'neutral700' : 'slate';
	const shadedColor = theme === 'light' ? 'neutral300' : theme === 'dark' ? 'neutral500' : 'purple500';
	let text = (
		<Text
			tx="heading"
			variant="medium"
			sx={{
				color: hasError ? 'coral' : value ? color : shadedColor,
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				marginTop: 1,
				whiteSpace: 'nowrap',
			}}
		>
			{lokalValue || placeholder}
		</Text>
	);

	if (withTooltip) {
		text = <Tooltip content={lokalValue}>{text}</Tooltip>;
	}

	const [isEditing, setIsEditing] = useState(isInitialEditing);
	if (!isEditing) {
		return (
			<Stack
				direction="horizontal"
				size="xxxSmall"
				sx={{
					alignItems: 'center',
					cursor: disabled ? undefined : 'pointer',
					height: 34,
				}}
				onClick={() => {
					if (!disabled) {
						setIsEditing(true);
					}
				}}
				width={width}
				minWidth={minWidth}
				maxWidth={maxWidth}
			>
				{!disabled && (
					<ButtonIcon variant="small" ml={1} minWidth={32}>
						<IconEdit color={color} />
					</ButtonIcon>
				)}
				{text}
			</Stack>
		);
	}

	return (
		<Container
			sx={{
				position: 'relative',
				width,
				minWidth,
				maxWidth,
			}}
		>
			<TextField
				autoFocus
				value={lokalValue}
				width={width}
				minWidth={minWidth}
				maxWidth={maxWidth}
				variant="smallHeading"
				pl={34}
				placeholder={placeholder}
				onChange={(e) => setLokalValue(e.target.value)}
				onKeyDown={(e) => {
					if (e.key === 'Enter') {
						setIsEditing(false);
						if (onChange) {
							onChange(lokalValue);
						}
					}
				}}
				onBlur={() => {
					setIsEditing(false);
					if (onChange) {
						onChange(lokalValue);
					}
				}}
				maxLength={maxLength === null ? undefined : (maxLength ?? 255)} // Provide null as a way to remove restriction, otherwise fall back to default value
			/>
			<IconEdit
				color="black"
				sx={{
					position: 'absolute',
					left: '8px',
					top: '7px',
				}}
			/>
		</Container>
	);
}
