/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	GetLicenseFeatureUsageResponseVO,
	GetLicenseResponseVO,
	GetLicenseSummaryVO,
	LicenseFeatureNameVO,
	LicenseFeatureVO,
	LicenseVO,
} from 'ui-api';
import { toDataStreamResultObservable } from 'utils/hooks/usePromise';
import { DataStreamResult } from 'utils/hooks/stream/result';
import { useObservable } from 'utils/hooks/useObservable';
import { filter, map, startWith, switchMap } from 'rxjs';
import { createContext, useContext } from 'react';
import axios from 'axios';

import { DateReviver, withReviver } from './common';
import { EventsApi } from './eventsApi';

export interface LicenseContextType extends LicenseVO {
	expires?: Date;
}

export const LicensesContext = createContext<LicenseContextType | null | undefined>(undefined);

export function useLicense(): LicenseContextType | null | undefined {
	return useContext(LicensesContext);
}

export const useLicenseFeature = (licenseFeature: LicenseFeatureNameVO): LicenseFeatureVO | undefined => {
	return useLicense()?.features.find((feature) => feature.name === licenseFeature);
};

export class LicenseApi {
	private eventsApi: EventsApi;

	constructor(eventsApi: EventsApi) {
		this.eventsApi = eventsApi;
	}

	async getActiveLicense(): Promise<GetLicenseResponseVO> {
		return (
			await axios.get<GetLicenseResponseVO>('/ui/license', {
				transformResponse: withReviver(DateReviver(['expires'])),
			})
		).data;
	}

	async getLicenseSummary(): Promise<GetLicenseSummaryVO> {
		return (
			await axios.get<GetLicenseSummaryVO>('/ui/license/summary', {
				transformResponse: withReviver(DateReviver(['expires'])),
			})
		).data;
	}

	private async getLicenseUsage(feature: LicenseFeatureNameVO): Promise<GetLicenseFeatureUsageResponseVO> {
		return (await axios.get<GetLicenseFeatureUsageResponseVO>(`/ui/license/featureUsage/${feature}`)).data;
	}

	useLicenseUsage$(feature: LicenseFeatureNameVO): DataStreamResult<GetLicenseFeatureUsageResponseVO> {
		return useObservable(
			() =>
				this.eventsApi.events$.pipe(
					filter((event) => 'license.usage.updated' === event.type && event.licenseFeatureName === feature),
					startWith(feature),
					map(() => this.getLicenseUsage(feature)),
					switchMap((res) => toDataStreamResultObservable<GetLicenseFeatureUsageResponseVO>(res)),
				),
			[feature],
		);
	}
}
