/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ActionVO, BaseExperimentStepVOUnion, ExperimentStepActionVO, ExperimentStepRadiusVO } from 'ui-api';
import MetricQuerySection from 'pages/experiments/components/metricQuery/MetricQuerySection';
import ExperimentActionHint from 'pages/experiments/components/experimentActionHint';
import StepEditHeader from 'pages/experiments/components/experimentStepEditHeader';
import { getColors } from 'pages/experiments/components/utils';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { Container, Message, Stack } from 'components';
import { useUrlState } from 'url/useUrlState';
import { ActionIcon } from 'hocs/ActionIcon';
import { Services } from 'services/services';
import { theme } from 'styles.v2/theme';
import { ReactElement } from 'react';
import { useField } from 'formik';

import { UrlState, selectedStepIdParam } from '../urlParams';
import ActionTargetSelection from './ActionTargetSelection';
import OutdatedNotification from './OutdatedNotification';
import { instantiateAction } from '../actionHelper';
import ActionTargetTable from './ActionTargetTable';
import ActionParameters from './ActionParameters';
import { ExperimentError } from '../types';
import useActions from '../useActions';

interface ActionProps {
	actionStep: ExperimentStepActionVO;
	expanded: boolean;
	disabled: boolean;
	stepPath: string;
	setExpanded: (expanded: boolean) => void;
	onDuplicate: () => void;
	onDelete: () => void;
	onClose: () => void;
}

export default function Action({ actionStep, stepPath, expanded, setExpanded, ...props }: ActionProps): ReactElement {
	const [, actionIdFieldMeta] = useField(`${stepPath}.actionId`);
	const { actions } = useActions();
	const { actionId } = actionStep;
	const action = actions.find((a) => a.id === actionId);

	if (!action) {
		return <OutdatedNotification actionStep={actionStep} />;
	}

	const actionIdFieldError = actionIdFieldMeta.error as ExperimentError | undefined;

	return (
		<Stack size="none" width="100%" height="100%">
			<StepActionHeader actionStep={actionStep} action={action} actions={actions} stepPath={stepPath} {...props} />

			<div
				style={{
					display: 'grid',
					gridTemplateColumns: expanded ? '544px 1fr' : '1fr',
					height: '100%',
					overflow: 'hidden',
				}}
			>
				<Stack
					size="none"
					sx={{
						width: '100%',
						pb: '12px',
						borderRight: '1px solid ' + theme.colors.neutral300,
						overflowY: 'auto',
					}}
				>
					{actionIdFieldError ? (
						<Container mt="small" mx="medium">
							<Message variant={'danger'} title={actionIdFieldError.message} />
						</Container>
					) : (
						<>
							<ActionTargetSelection
								actionStep={actionStep}
								stepPath={stepPath}
								expanded={expanded}
								action={action}
								setExpanded={setExpanded}
							/>

							{action.hint && (
								<Container m="small">
									<ExperimentActionHint hint={action.hint} />
								</Container>
							)}

							<ActionParameters action={action} actionStep={actionStep} stepPath={stepPath} />

							{stepPath && action.metricQueryParameters.length > 0 && (
								<MetricQuerySection step={actionStep} stepPath={stepPath} action={action} disabled={false} />
							)}
						</>
					)}
				</Stack>

				{expanded && <ActionTargetTable actionStep={actionStep} action={action} stepPath={stepPath} />}
			</div>
		</Stack>
	);
}

interface StepActionHeaderProps {
	actionStep: ExperimentStepActionVO;
	actions: ActionVO[];
	disabled: boolean;
	action: ActionVO;
	stepPath: string;
	onDuplicate: () => void;
	onDelete: () => void;
	onClose: () => void;
}

function StepActionHeader({
	actionStep,
	stepPath,
	disabled,
	actions,
	action,
	onDuplicate,
	onDelete,
	onClose,
}: StepActionHeaderProps): ReactElement {
	const { actionId } = actionStep;

	const [, , { setValue, setTouched }] = useField<BaseExperimentStepVOUnion>(stepPath);
	const [, , updateUrlState] = useUrlState<UrlState>([selectedStepIdParam]);

	const [actionName] = useAsyncState<string>(
		async () => await Services.actions.findActionNameWithTargetTypeIfNotUnique(actionId),
		[actionId],
	);

	return (
		<StepEditHeader
			icon={<ActionIcon id={actionId} />}
			caption={actionName.value || ''}
			{...getColors(action.kind)}
			stepPath={stepPath}
			step={actionStep}
			disabled={disabled}
			action={action}
			onReplaceStep={(newActionId) => {
				const newAction = actions.find((a) => a.id === newActionId);
				if (newAction) {
					const newStep = instantiateAction(newAction);
					if (
						isExperimentStepActionVO(newStep) &&
						newStep.blastRadius &&
						newStep.blastRadius.targetType &&
						actionStep.blastRadius &&
						Object.keys(actionStep.blastRadius).length > 0
					) {
						// keep the blast raduis properties if both have a blast radius
						newStep.blastRadius = copyBlastRadius(actionStep.blastRadius, newStep.blastRadius);
					}

					// keep the duration if both have a duration
					if (newStep.parameters.duration && actionStep.parameters.duration) {
						newStep.parameters.duration = actionStep.parameters.duration;
					}

					setValue(newStep);
					setTouched(true);
					updateUrlState({ selectedStepId: newStep.id });
				}
			}}
			onDuplicate={onDuplicate}
			onDelete={onDelete}
			onClose={onClose}
		/>
	);
}

function copyBlastRadius(a: ExperimentStepRadiusVO, b: ExperimentStepRadiusVO): ExperimentStepRadiusVO {
	return {
		...b,
		predicate: a.predicate,
	};
}

function isExperimentStepActionVO(step: BaseExperimentStepVOUnion): step is ExperimentStepActionVO {
	return (step as ExperimentStepActionVO).actionId !== undefined;
}
