/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	ButtonIcon,
	Li,
	Link,
	Stack,
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeadCell,
	TableRow,
	TableSortLink,
	UserIcon,
} from 'components';
import DropdownContentFrame from 'components/Select/Dropdown/presets/components/DropdownContentFrame';
import DropdownSearchInput from 'pages/components/SearchBar/DropdownSearchInput';
import { Notification } from 'components/Notifications/Notifications';
import TableLoadingRow from 'components/Table/TableLoadingRow';
import { IconDelete, IconUserAdd } from 'components/icons';
import { Flex, Text } from '@steadybit/ui-components-lib';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { Order, usePage } from 'utils/hooks/usePage';
import { useTenant } from 'tenancy/useTenant';
import { Services } from 'services/services';
import { useFormikContext } from 'formik';
import { isUserAdmin } from 'utils/user';
import { theme } from 'styles.v2/theme';
import { includes } from 'utils/string';
import Ul from 'components/List/Ul';
import { UserVO } from 'ui-api';
import React from 'react';

import { TeamRoleSelect } from './teamRoleSelect';
import { TeamFormValues } from './teamTypes';

const SORT_NAME_ASC: Order[] = [['name', 'asc', 'ignoreCase']];
const SORT_NAME_DESC: Order[] = [['name', 'desc', 'ignoreCase']];
const SORT_EMAIL_ASC: Order[] = [['email', 'asc']];
const SORT_EMAIL_DESC: Order[] = [['email', 'desc']];

export const TeamMembers: React.VFC<{ teamId?: string; disabled?: boolean }> = ({ teamId, disabled = false }) => {
	const formik = useFormikContext<TeamFormValues>();

	const tenant = useTenant();
	const isAdmin = isUserAdmin(tenant.user);

	const page = usePage(`/${teamId}`, { size: 1000, sort: SORT_NAME_ASC });
	const [users] = useAsyncState(() => Services.users.fetchUsers(page.pageParams), [page]);
	const canAddMembers = !formik.errors.members;

	const isLoading = users.loading;

	function handleAddMember(value: string): void {
		if (!formik.values.members.find((m) => m.username === value)) {
			formik.setFieldValue('members', [...formik.values.members, { username: value, role: 'MEMBER' }]);
		}
	}

	const handleRoleChange = (user: UserVO, role: string): void => {
		const indexOfUser = formik.values.members.findIndex((m) => m.username === user.username);
		if (indexOfUser >= 0) {
			formik.setFieldValue(`members[${indexOfUser}].role`, role);
		}
	};

	const handleRemoveMember = (user: UserVO) => () => {
		const newMembers = formik.values.members.filter((m) => m.username !== user.username);
		formik.setFieldValue('members', newMembers);
	};

	const resolvedMembers: UserVO[] = [];
	const resolvedUsers: UserVO[] = [];
	if (users.value) {
		for (const user of users.value.content) {
			if (formik.values.members.find((m) => m.username === user.username)) {
				resolvedMembers.push(user);
			} else {
				resolvedUsers.push(user);
			}
		}
	}

	return (
		<Stack>
			{!canAddMembers && (
				<Notification
					content={{
						message: (
							<Text>
								Only users who are already part of a team (full members) can be added here. To invite someone who is not
								a full member, please{' '}
								{isAdmin ? (
									<Link href="https://steadybit.com/contact-us/" external>
										contact us
									</Link>
								) : (
									'contact your admin'
								)}
								.
							</Text>
						),
						level: 'warning',
					}}
				/>
			)}

			{!disabled && (
				<DropdownSearchInput
					small
					placeholder="Add existing members."
					renderDropdownContent={({ value, width, setValue, closeMenu }) => {
						const filteredUsers = resolvedUsers.filter((user) => {
							const matchesName = includes(user.name, value);
							const matchesMail = user.email ? includes(user.email, value) : false;
							return matchesName || matchesMail;
						});

						return (
							<DropdownContentFrame maxHeight={400} maxWidth={width} sx={{ minWidth: width }}>
								<>
									{filteredUsers.length === 0 && (
										<Text style={{ color: theme.colors.neutral600, padding: theme.space.small }}>
											No users matching your query.
										</Text>
									)}
									<Ul>
										{filteredUsers.map((user) => {
											const isFullMember = user.teams.length > 0;
											return (
												<Li
													key={user.email}
													sx={{
														padding: '7px 4px',
														borderBottom: '1px solid ' + theme.colors.neutral300,
														cursor: 'pointer',
														'&:hover': {
															backgroundColor: theme.colors.neutral100,
														},
													}}
													onClick={() => {
														handleAddMember(user.username);
														closeMenu();
														setValue('');
													}}
												>
													<Flex direction="horizontal" spacing="xxSmall" justify="spread">
														<Flex direction="horizontal" spacing="xxSmall">
															<IconUserAdd color="slate" />
															<Text type="smallStrong" style={{ color: theme.colors.neutral800 }}>
																{user.name}
															</Text>
															{user.email && (
																<Text type="small" style={{ color: theme.colors.neutral600 }}>
																	({user.email})
																</Text>
															)}
														</Flex>
														{isFullMember && (
															<Text type="small" style={{ color: theme.colors.neutral600 }}>
																Already a full member
															</Text>
														)}
													</Flex>
												</Li>
											);
										})}
									</Ul>
								</>
							</DropdownContentFrame>
						);
					}}
				/>
			)}

			<Table width={'100%'} data-cy="team-table">
				<TableHead>
					<TableRow>
						<TableHeadCell>
							<TableSortLink
								sort={page.getDirection(SORT_NAME_ASC, SORT_NAME_DESC)}
								to={page.toggleSort(SORT_NAME_ASC, SORT_NAME_DESC).toString()}
							>
								Name
							</TableSortLink>
						</TableHeadCell>
						<TableHeadCell>
							<TableSortLink
								sort={page.getDirection(SORT_EMAIL_ASC, SORT_EMAIL_DESC)}
								to={page.toggleSort(SORT_EMAIL_ASC, SORT_EMAIL_DESC).toString()}
							>
								E-Mail
							</TableSortLink>
						</TableHeadCell>
						<TableHeadCell>Role</TableHeadCell>
						<TableHeadCell />
					</TableRow>
				</TableHead>
				<TableBody>
					{isLoading ? (
						<>
							<TableLoadingRow numColumns={3} />
							<TableLoadingRow numColumns={3} />
							<TableLoadingRow numColumns={3} />
						</>
					) : (
						resolvedMembers.map((user) => (
							<MemberRow
								key={user.username}
								user={user}
								role={formik.values.members.find((m) => m.username === user.username)?.role ?? undefined}
								disabled={disabled}
								onRoleChange={(role) => handleRoleChange(user, role)}
								onRemove={handleRemoveMember(user)}
							/>
						))
					)}
				</TableBody>
			</Table>
		</Stack>
	);
};

interface MemberRowProps {
	user: UserVO;
	role?: string;
	disabled?: boolean;
	onRoleChange: (r: string) => void;
	onRemove: () => void;
}

const MemberRow: React.VFC<MemberRowProps> = ({ user, role, disabled, onRoleChange, onRemove }) => {
	return (
		<TableRow hoverable={true}>
			<TableDataCell data-private>
				<UserIcon
					variant="medium"
					src={user.pictureUrl}
					mr={5}
					sx={{
						minWidth: 24,
						minHeight: 24,
						maxWidth: 24,
						maxHeight: 24,
					}}
				/>
				{user.name}
			</TableDataCell>
			<TableDataCell data-private>{user?.email ?? ''}</TableDataCell>
			<TableDataCell>{!disabled ? <TeamRoleSelect value={role} onChange={onRoleChange} /> : role}</TableDataCell>
			<TableDataCell justifyContent={'flex-end'}>
				{!disabled ? (
					<ButtonIcon muted onClick={onRemove}>
						<IconDelete color="neutral600" />
					</ButtonIcon>
				) : null}
			</TableDataCell>
		</TableRow>
	);
};
