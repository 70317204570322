/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	IconAdvanced,
	IconAgent,
	IconApiAccessToken,
	IconEnvironment,
	IconExtension,
	IconHelp,
	IconHub,
	IconIntegrations,
	IconLicense,
	IconLogo,
	IconTeam,
	IconTemplate,
	IconUser,
} from 'components/icons';
import { LicenseInfo } from 'pages/settings/components/Sidebar/LicenseInfo';
import ServerInfo from 'pages/settings/components/Sidebar/ServerInfo';
import { useLicenseFeature } from 'services/licenseApi';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactElement, useState } from 'react';
import { Stack, Text } from 'components';

import useFeatureFlag from '../../../services/useFeatureFlag';
import LicenseTooltipContent from '../LicenseTooltipContent';
import { useTenant } from '../../../tenancy/useTenant';
import NavigationItem from './NavigationItem';
import './SettingsNavigation.css';

interface SettingsNavigationProps {
	amplitudeExportAvailable: boolean;
	metricExportAvailable: boolean;
	templatesAvailable: boolean;
	dbExportAvailable: boolean;
	isAdmin: boolean;
	activePath: string;
	/**
	 * @deprecated This is only for the storybook
	 */
	initExpanded?: boolean;
	/**
	 * @deprecated This is only for the storybook
	 */
	isShowcaseMode?: boolean;
}

export default function SettingsNavigation({
	amplitudeExportAvailable,
	metricExportAvailable,
	templatesAvailable,
	dbExportAvailable,
	activePath,
	isAdmin,
	isShowcaseMode = false,
	initExpanded = false,
}: SettingsNavigationProps): ReactElement {
	const tenant = useTenant();
	const [expanded, setExpanded] = useState(initExpanded);
	const licensesUIEnabled = useFeatureFlag('licensesUIEnabled');
	const supportAccessToggleAvailable = tenant.type !== 'ONPREM' && isAdmin;

	const showCustomLabeling = useLicenseFeature('CUSTOM_LABELING');

	return (
		<div
			className="settings-navigation"
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',

				width: expanded ? '248px' : '48px',
				height: 'calc(100% - 68px)',
				padding: '36px 16px 32px 16px',

				background: 'linear-gradient(0deg, #3B2F83 0%, #5B48CA 100%)',
				transition: 'width 0.3s',
				overflowX: 'hidden',
				overflowY: expanded ? 'auto' : 'hidden',
			}}
			onMouseEnter={() => setExpanded(true)}
			onMouseLeave={() => setExpanded(false)}
		>
			<Stack size="xLarge">
				<Stack size="small">
					<Text
						variant="mediumStrong"
						color="neutral000"
						sx={{
							opacity: expanded ? 1 : 0,
							transition: 'opacity 0.2s',
						}}
					>
						Settings
					</Text>
					<Text
						variant="small"
						color="neutral000"
						sx={{
							opacity: expanded ? 1 : 0,
							transition: 'opacity 0.2s',
						}}
					>
						ORGANISATION
					</Text>
					<Stack size="xxSmall">
						<NavigationItem
							href="/settings/teams"
							isActive={activePath.startsWith('/settings/teams')}
							icon={IconTeam}
							label="Teams"
							expanded={expanded}
							isShowcaseMode={isShowcaseMode}
							data-cy="settings-teams"
						/>
						<NavigationItem
							href="/settings/users"
							isActive={activePath.startsWith('/settings/users')}
							icon={IconUser}
							label="Users"
							expanded={expanded}
							isShowcaseMode={isShowcaseMode}
							data-cy="settings-users"
						/>
						<NavigationItem
							href="/settings/environments"
							isActive={activePath.startsWith('/settings/environments')}
							icon={IconEnvironment}
							label="Environments"
							expanded={expanded}
							isShowcaseMode={isShowcaseMode}
							data-cy="settings-environments"
						/>
						<NavigationItem
							href="/settings/api-tokens"
							isActive={activePath.startsWith('/settings/api-tokens')}
							icon={IconApiAccessToken}
							label="API Access Tokens"
							expanded={expanded}
							isShowcaseMode={isShowcaseMode}
							data-cy="settings-apitokens"
						/>
					</Stack>
				</Stack>
				<Stack size="small">
					<Text
						variant="small"
						color="neutral000"
						sx={{
							opacity: expanded ? 1 : 0,
							transition: 'opacity 0.2s',
						}}
					>
						APPLICATION
					</Text>
					<Stack size="xxSmall">
						<NavigationItem
							href="/settings/agents"
							isActive={activePath.startsWith('/settings/agents')}
							icon={IconAgent}
							label="Agents"
							expanded={expanded}
							isShowcaseMode={isShowcaseMode}
							data-cy="settings-agents"
						/>
						<NavigationItem
							href="/settings/integrations"
							isActive={activePath.startsWith('/settings/integrations')}
							icon={IconIntegrations}
							label="Integrations"
							expanded={expanded}
							isShowcaseMode={isShowcaseMode}
							data-cy="settings-integration"
						/>
						<NavigationItem
							href="/settings/extensions"
							isActive={activePath.startsWith('/settings/extensions')}
							icon={IconExtension}
							label="Extensions"
							expanded={expanded}
							isShowcaseMode={isShowcaseMode}
							data-cy="settings-extensions"
						/>
						<NavigationItem
							href="/settings/templates"
							isActive={activePath.startsWith('/settings/templates')}
							disabled={!templatesAvailable}
							tooltip={!templatesAvailable ? <LicenseTooltipContent /> : undefined}
							icon={IconTemplate}
							label="Templates"
							expanded={expanded}
							isShowcaseMode={isShowcaseMode}
							data-cy="settings-templates"
						/>
						<NavigationItem
							href="/settings/hubs"
							isActive={activePath.startsWith('/settings/hubs')}
							icon={IconHub}
							label="Hubs"
							expanded={expanded}
							isShowcaseMode={isShowcaseMode}
							data-cy="settings-hubs"
						/>
						{showCustomLabeling && (
							<NavigationItem
								href="/settings/customization"
								isActive={activePath.startsWith('/settings/customization')}
								icon={IconAdvanced}
								label="Customization"
								expanded={expanded}
								isShowcaseMode={isShowcaseMode}
								data-cy="settings-customize"
							/>
						)}
						{licensesUIEnabled && isAdmin && (
							<NavigationItem
								href="/settings/license"
								isActive={activePath.startsWith('/settings/license')}
								icon={IconLicense}
								label="License"
								expanded={expanded}
								isShowcaseMode={isShowcaseMode}
								data-cy="settings-license"
							/>
						)}
						{(metricExportAvailable ||
							dbExportAvailable ||
							amplitudeExportAvailable ||
							supportAccessToggleAvailable) && (
							<NavigationItem
								href="/settings/troubleshooting"
								isActive={activePath.startsWith('/settings/troubleshooting')}
								icon={IconHelp}
								label="Troubleshooting"
								expanded={expanded}
								isShowcaseMode={isShowcaseMode}
								data-cy="settings-troubleshooting"
							/>
						)}
					</Stack>
				</Stack>

				<NavigationItem
					href="/game"
					label="Need a break?"
					icon={IconLogo}
					expanded={expanded}
					isShowcaseMode={isShowcaseMode}
					data-cy="settings-game"
				/>
			</Stack>

			{expanded && !isShowcaseMode && (
				<AnimatePresence initial>
					<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.2 }}>
						<Stack
							size="xSmall"
							sx={{
								padding: '16px',
								borderRadius: '8px',
							}}
						>
							<ServerInfo color="neutral000" />
							<LicenseInfo color="neutral000" />
						</Stack>
					</motion.div>
				</AnimatePresence>
			)}
		</div>
	);
}
