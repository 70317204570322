/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import {
	CreateEnvironmentRequest,
	EnvironmentSummaryShortVO,
	EnvironmentVO,
	GetEnvironmentsPageResponse,
	GetEnvironmentSummariesShortResponse,
	GetEnvironmentVariablesResponse,
	TargetPredicateVO,
	UpdateEnvironmentRequest,
	UpdateEnvironmentVariablesRequest,
	VariableVO,
} from 'ui-api';
import { toDataStreamResultObservable } from 'utils/hooks/usePromise';
import { DataStreamResult } from 'utils/hooks/stream/result';
import { useObservable } from 'utils/hooks/useObservable';
import { filter, map, startWith, switchMap } from 'rxjs';
import { PageParams } from 'utils/hooks/usePage';
import axios from 'axios';

import { EventsApi } from './eventsApi';

export class EnvironmentsApi {
	private eventsApi: EventsApi;
	constructor(eventsApi: EventsApi) {
		this.eventsApi = eventsApi;
	}

	async fetchEnvironments(
		page: PageParams | undefined = new PageParams(0, 1_000, [
			['isGlobal', 'desc', 'ignoreCase'],
			['name', 'asc', 'ignoreCase'],
		]),
	): Promise<GetEnvironmentsPageResponse> {
		return (await axios.get<GetEnvironmentsPageResponse>('/ui/environments', { params: page.toUrlSearchParams() }))
			.data;
	}

	async fetchEnvironment(id: string): Promise<EnvironmentVO> {
		return (await axios.get<EnvironmentVO>(`/ui/environments/${id}`)).data;
	}

	async fetchEnvironmentsById(ids: string[]): Promise<Map<string, EnvironmentSummaryShortVO>> {
		const params = new URLSearchParams();
		ids.forEach((id) => id && params.append('id', id));
		const environments = (await axios.get<GetEnvironmentSummariesShortResponse>('/ui/environments', { params })).data
			.content;
		const result = new Map<string, EnvironmentSummaryShortVO>();
		environments.forEach((a) => result.set(a.id, a));
		return result;
	}

	async createEnvironment(body: CreateEnvironmentRequest): Promise<EnvironmentVO> {
		return (await axios.post<EnvironmentVO>('/ui/environments', body)).data;
	}

	async deleteEnvironment(id: string): Promise<void> {
		await axios.delete(`/ui/environments/${id}`);
	}

	async updateEnvironment(id: string, body: UpdateEnvironmentRequest): Promise<void> {
		await axios.post(`/ui/environments/${id}`, body);
	}

	async fetchEnvironmentVariables(id: string): Promise<VariableVO[]> {
		return (await axios.get<GetEnvironmentVariablesResponse>(`/ui/environments/${id}/variables`)).data.content;
	}

	async updateEnvironmentVariables(id: string, body: UpdateEnvironmentVariablesRequest): Promise<void> {
		await axios.post(`/ui/environments/${id}/variables`, body);
	}

	async countTargets(predicate: TargetPredicateVO): Promise<Record<string, number>> {
		return (await axios.post<Record<string, number>>('/ui/environments/count', predicate)).data;
	}

	async getPermissions(): Promise<string[]> {
		return (await axios.get('/ui/environments/permissions')).data;
	}

	useEnvironmentVariables$(environmentId: string): DataStreamResult<VariableVO[]> {
		return useObservable(
			() =>
				this.eventsApi.events$.pipe(
					filter((event) => 'environment.variables.updated' === event.type && event.environmentId === environmentId),
					startWith(environmentId),
					map(() => this.fetchEnvironmentVariables(environmentId)),
					switchMap((res) => toDataStreamResultObservable<VariableVO[]>(res)),
				),
			[environmentId],
		);
	}
}
