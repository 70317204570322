/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import DropdownContentFrame from 'components/Select/Dropdown/presets/components/DropdownContentFrame';
import EnvironmentSelector from 'components/EnvironmentSelector/EnvironmentSelector';
import DropdownPill from 'components/Select/Dropdown/DropdownPill';
import { Stack, TutorialTooltip } from 'components';
import { useField, useFormikContext } from 'formik';
import { IconFunction } from 'components/icons';
import { useUpdateEffect } from 'react-use';
import React, { useState } from 'react';
import { TUTORIALS } from 'tutorials';

import ExperimentAndEnvironmentVariables from './ExperimentAndEnvironmentVariables';
import { ExperimentFormValues } from '../../experiment';

export const ExperimentSubHeaderEnvironments: React.VFC<{
	disabled: boolean;
}> = ({ disabled }) => {
	const formik = useFormikContext<ExperimentFormValues>();
	const { teamId, environmentId, experimentVariables = [], variables = [] } = formik.values;
	const { setFieldValue } = formik;

	// when teamId changes reset environmentId
	useUpdateEffect(() => {
		setFieldValue('environmentId', '');
	}, [teamId, setFieldValue]);

	const [, environmentIdMeta] = useField('environmentId');
	const hasEnvironmentError = Boolean(environmentIdMeta.error);

	const hasVariablesError: boolean =
		Boolean(formik.errors.overallExperimentVariables) ||
		Boolean(formik.errors.experimentVariables) ||
		experimentVariables.some((variable) => !variable.value);

	const [isDeletingVariable, setIsDeletingVariable] = useState(false);
	const totalNumberOfVariables = variables.length + experimentVariables.length;

	return (
		<Stack flexShrink={0} direction={'horizontal'}>
			<Stack direction="horizontal" size="none">
				<EnvironmentSelector
					selectedEnvironmentId={environmentId}
					hasError={hasEnvironmentError}
					disabled={disabled}
					teamId={teamId}
					selectEnvironmentId={(_environmentId) => formik.setFieldValue('environmentId', _environmentId)}
				/>

				{!environmentId && (
					<TutorialTooltip
						light
						hideIcon
						hideSkip
						showAlways
						placement="right"
						{...TUTORIALS.experiments.noEnvironmentSelected}
					/>
				)}
			</Stack>

			<DropdownPill
				id="environmentVariablesDropDownButton"
				variant={
					hasEnvironmentError
						? 'default'
						: hasVariablesError
							? 'error'
							: totalNumberOfVariables > 0
								? 'highlighted'
								: 'default'
				}
				disableCloseOnClickAway={isDeletingVariable}
				disabled={disabled || hasEnvironmentError}
				Icon={IconFunction}
				placement="bottom-start"
				value={totalNumberOfVariables === 0 ? 'No variables' : `Variables (${totalNumberOfVariables})`}
			>
				{() => (
					<DropdownContentFrame sx={{ borderRadius: '4px' }}>
						<ExperimentAndEnvironmentVariables
							environmentVariables={variables}
							setIsDeletingVariable={setIsDeletingVariable}
						/>
					</DropdownContentFrame>
				)}
			</DropdownPill>
		</Stack>
	);
};
