/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

import { setupAxiosServerDateInterceptor } from 'utils/now';
import { setupAxiosErrorInterceptor } from 'utils/error';
import { getBaseHref } from 'utils/getBaseHref';
import axios from 'axios';

import { LegacyExperimentsTemplateApi } from './legacyExperimentsTemplateApi';
import { ExecutionArtifactsApi } from './executionArtifactsApi';
import { AdviceDefinitionsApi } from './adviceDefinitionsApi';
import { ExecutionMetricsApi } from './executionMetricsApi';
import { ExecutionLogsApi } from './executionLogsApi';
import { NotificationsApi } from './notificationsApi';
import { AccessTokensApi } from './accessTokensApi';
import { EnvironmentsApi } from './environmentsApi';
import { LandscapeV2Api } from './landscapeV2Api';
import { PermissionsApi } from './permissionsApi';
import { ExperimentApi } from './experimentApi';
import { KillswitchApi } from './killswitchApi';
import { ServerInfoApi } from './serverInfoApi';
import { DashboardApi } from './dashboardApi';
import { TemplatesApi } from './templatesApi';
import { WebhooksApi } from './webhooksApi';
import { SettingsApi } from './settingsApi';
import { ActionsApi } from './actionsApi';
import { TargetsApi } from './targetsApi';
import { TenantsApi } from './tenantsApi';
import { LicenseApi } from './licenseApi';
import { AdviceApi } from './adviceApi';
import { AgentsApi } from './agentsApi';
import { EditorApi } from './editorApi';
import { EventsApi } from './eventsApi';
import { ExportApi } from './exportApi';
import { HealthApi } from './HealthApi';
import { FilesApi } from './filesApi';
import { TeamsApi } from './teamsApi';
import { UsersApi } from './usersApi';
import { HubsApi } from './hubsApi';
import { AuthApi } from './authApi';

const setupAxiosBaseUrl = (): void => {
	if (process.env.STORYBOOK === 'true') {
		// When the UI is running within the Storybook, the baseUrl identified
		// is some gibberish that we cannot rely on to configure axios. Instead,
		// configure just the expected (and in reality existing in production)
		axios.defaults.baseURL = '/';
	} else {
		axios.defaults.baseURL = getBaseHref();
	}
};

class ServiceContainer {
	auth: AuthApi;
	events: EventsApi;
	tenants: TenantsApi;
	dashboard: DashboardApi;
	executionArtifacts: ExecutionArtifactsApi;
	executionLogs: ExecutionLogsApi;
	executionMetrics: ExecutionMetricsApi;
	editorApi: EditorApi;
	experiments: ExperimentApi;
	agents: AgentsApi;
	targets: TargetsApi;
	adviceDefinitionsApi: AdviceDefinitionsApi;
	actions: ActionsApi;
	teams: TeamsApi;
	environments: EnvironmentsApi;
	users: UsersApi;
	killswitch: KillswitchApi;
	accessTokens: AccessTokensApi;
	webhooks: WebhooksApi;
	permissionsApi: PermissionsApi;
	notificationsApi: NotificationsApi;
	landscapeV2: LandscapeV2Api;
	legacyExperimentTemplates: LegacyExperimentsTemplateApi;
	files: FilesApi;
	serverInfo: ServerInfoApi;
	exportApi: ExportApi;
	healthApi: HealthApi;
	hubsApi: HubsApi;
	adviceApi: AdviceApi;
	templatesApi: TemplatesApi;
	hubApi: HubsApi;
	settingsApi: SettingsApi;
	licenseApi: LicenseApi;

	constructor() {
		setupAxiosBaseUrl();
		setupAxiosErrorInterceptor();
		setupAxiosServerDateInterceptor();

		this.auth = new AuthApi();
		this.events = new EventsApi();
		this.licenseApi = new LicenseApi(this.events);
		this.tenants = new TenantsApi();
		this.dashboard = new DashboardApi();
		this.executionArtifacts = new ExecutionArtifactsApi();
		this.executionLogs = new ExecutionLogsApi();
		this.executionMetrics = new ExecutionMetricsApi();
		this.agents = new AgentsApi(this.tenants);
		this.targets = new TargetsApi(this.events);
		this.actions = new ActionsApi(this.events);
		this.teams = new TeamsApi();
		this.environments = new EnvironmentsApi(this.events);
		this.users = new UsersApi(this.events);
		this.killswitch = new KillswitchApi(this.events);
		this.accessTokens = new AccessTokensApi();
		this.webhooks = new WebhooksApi();
		this.hubsApi = new HubsApi();
		this.experiments = new ExperimentApi(this.events);
		this.landscapeV2 = new LandscapeV2Api(this.targets);
		this.legacyExperimentTemplates = new LegacyExperimentsTemplateApi(this.experiments);
		this.files = new FilesApi();
		this.serverInfo = new ServerInfoApi();
		this.exportApi = new ExportApi(this.tenants);
		this.healthApi = new HealthApi();
		this.editorApi = new EditorApi();
		this.adviceDefinitionsApi = new AdviceDefinitionsApi();
		this.adviceApi = new AdviceApi();
		this.templatesApi = new TemplatesApi();
		this.settingsApi = new SettingsApi();
		this.hubApi = new HubsApi();
		this.permissionsApi = new PermissionsApi(
			this.events,
			this.agents,
			this.environments,
			this.experiments,
			this.templatesApi,
			this.teams,
			this.hubApi,
		);
		this.notificationsApi = new NotificationsApi(this.events);
	}
}

export const Services = new ServiceContainer();
