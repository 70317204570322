/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	IconConsole,
	IconDocumentation,
	IconExperiment,
	IconExplore,
	IconHelp,
	IconMessage,
	IconSettings,
	IconState,
} from 'components/icons';
import NavigationLogo from 'components/Branding/NavigationLogo';
import { useIntercom } from 'react-use-intercom';
import { ReactElement, useState } from 'react';
import { useTenant } from 'tenancy/useTenant';
import { isUserAdmin } from 'utils/user';
import { theme } from 'styles.v2/theme';
import { Stack } from 'components';

import ActivityModal from './ActivityWidget/components/ActivityModal';
import { isIntercomAvailable } from '../../../tracking/intercom';
import ActivityWidget from './ActivityWidget/ActivityWidget';
import TenantAndTeamSwitcher from './TenantAndTeamSwitcher';
import UserItem, { AccountSettingsModal } from './UserItem';
import SettingsNavigation from './SettingsNavigation';
import { useUser } from '../../../services/usersApi';
import NavigationItem from './NavigationItem';
import ChangelogItem from './ChangelogItem';
import './MainNavigation.css';

interface MainNavigationProps {
	amplitudeExportAvailable: boolean;
	withSettingsNavigation?: boolean;
	metricExportAvailable: boolean;
	templatesAvailable: boolean;
	dbExportAvailable: boolean;
	activePath: string;
	/**
	 * @deprecated This is only for the storybook
	 */
	isShowcaseMode?: boolean;
	/**
	 * @deprecated This is only for the storybook
	 */
	initExpanded?: boolean;
}

export default function MainNavigation({
	amplitudeExportAvailable = false,
	withSettingsNavigation = false,
	metricExportAvailable = false,
	templatesAvailable = false,
	dbExportAvailable = false,
	isShowcaseMode = false,
	initExpanded = false,
	activePath,
}: MainNavigationProps): ReactElement {
	const [showActivityModal, setShowActivityModal] = useState(false);
	const [showTeamSwitcher, setShowTeamSwitcher] = useState(false);
	const [showUserMenu, setShowUserMenu] = useState(false);
	const [expanded, setExpanded] = useState(initExpanded);
	const [isMouseOver, setIsMouseOver] = useState(initExpanded);
	const tenant = useTenant();
	const user = useUser();

	const isAdmin = isUserAdmin(tenant.user);

	const intercomAvailable = isIntercomAvailable(tenant, user);
	const { show: showIntercom } = useIntercom();

	return (
		<>
			{showActivityModal && (
				<ActivityModal
					onClose={() => {
						setShowActivityModal(false);
						if (!isMouseOver && expanded) {
							setExpanded(false);
						}
					}}
				/>
			)}
			{showUserMenu && (
				<AccountSettingsModal
					onClose={() => {
						setShowUserMenu(false);
						if (!isMouseOver && expanded) {
							setExpanded(false);
						}
					}}
				/>
			)}
			<div
				style={{
					display: 'flex',
					height: '100%',
					width: 'fit-content',
					boxShadow: 'rgba(0, 0, 0, 0.25) 2px 0px 4px',
				}}
			>
				<div
					className="main-navigation"
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',

						width: expanded ? '248px' : '48px',
						padding: '32px 16px',

						background: 'linear-gradient(0deg, #1B163D 0%, #3B2F83 100%)',
						transition: 'width 0.3s',
						overflowX: 'hidden',
						overflowY: expanded ? 'auto' : 'hidden',
					}}
					onMouseEnter={() => {
						setExpanded(true);
						setIsMouseOver(true);
					}}
					onMouseLeave={() => {
						if (!showActivityModal && !showUserMenu && !showTeamSwitcher) {
							setExpanded(false);
						}
						setIsMouseOver(false);
					}}
					data-cy="main-navigation"
				>
					<Stack size="large">
						<NavigationLogo
							small={!expanded}
							customLogo={tenant.navigationLogo}
							customLogoSmall={tenant.navigationLogoSmall}
						/>

						{isShowcaseMode || !tenant.initialized ? null : (
							<TenantAndTeamSwitcher
								expanded={expanded}
								setShowTeamSwitcher={(v) => {
									setShowTeamSwitcher(v);
									if (!v && !isMouseOver && expanded) {
										setExpanded(false);
									}
								}}
							/>
						)}

						{!isShowcaseMode && (
							// The widget as an indicator which needs to overflow the main navigation container
							<div style={{ marginRight: expanded ? '0px' : '-10px' }}>
								<ActivityWidget expanded={expanded} onClick={() => setShowActivityModal(true)} />
							</div>
						)}

						<Stack size="xxSmall">
							<NavigationItem
								href="/dashboard"
								isActive={activePath.startsWith('/dashboard')}
								label="Dashboard"
								icon={IconState}
								expanded={expanded}
								isShowcaseMode={isShowcaseMode}
								data-cy="dashboard"
							/>
							<NavigationItem
								href="/landscape"
								isActive={activePath.startsWith('/landscape')}
								icon={IconExplore}
								label="Explorer"
								expanded={expanded}
								isShowcaseMode={isShowcaseMode}
							/>
							<NavigationItem
								href="/experiments"
								isActive={activePath.startsWith('/experiments') || activePath.startsWith('/experimentruns')}
								icon={IconExperiment}
								label="Experiments"
								expanded={expanded}
								isShowcaseMode={isShowcaseMode}
								data-cy="experiments"
							/>
						</Stack>
					</Stack>

					<Stack size="medium">
						<NavigationItem
							href="/settings"
							isActive={activePath.startsWith('/settings')}
							icon={IconSettings}
							label="Settings"
							expanded={expanded}
							isShowcaseMode={isShowcaseMode}
							data-cy="settings"
						/>
						<Stack size="xxSmall">
							<NavigationItem
								href="https://docs.steadybit.com/"
								icon={IconDocumentation}
								label="Documentation"
								expanded={expanded}
								isShowcaseMode={isShowcaseMode}
								external
							/>

							<ChangelogItem expanded={expanded} />

							{intercomAvailable ? (
								<NavigationItem
									onClick={(e) => {
										showIntercom();
										e.stopPropagation();
										e.preventDefault();
									}}
									icon={IconMessage}
									label="Chat Support"
									expanded={expanded}
									isShowcaseMode={isShowcaseMode}
								/>
							) : (
								<NavigationItem
									href="https://steadybit.com/contact"
									icon={IconHelp}
									label="Support"
									expanded={expanded}
									isShowcaseMode={isShowcaseMode}
									external
								/>
							)}
						</Stack>
						<Stack size="medium">
							<NavigationItem
								href="https://hub.steadybit.com/"
								icon={IconConsole}
								label="Reliability Hub"
								expanded={expanded}
								isShowcaseMode={isShowcaseMode}
								code
								external
							/>
							<div style={{ borderTop: '1px solid ' + theme.colors.purple600 }} />
							<UserItem expanded={expanded} onClick={() => setShowUserMenu(true)} />
						</Stack>
					</Stack>
				</div>
				{withSettingsNavigation && (
					<SettingsNavigation
						amplitudeExportAvailable={amplitudeExportAvailable}
						metricExportAvailable={metricExportAvailable}
						templatesAvailable={templatesAvailable}
						dbExportAvailable={dbExportAvailable}
						isShowcaseMode={isShowcaseMode}
						initExpanded={initExpanded}
						activePath={activePath}
						isAdmin={isAdmin}
					/>
				)}
			</div>
		</>
	);
}
