/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { UnitFieldUnit } from 'components';
import { ReactElement } from 'react';

import { ControlWrapper, Options } from './Common';
import { Input } from './Integer';

interface BitrateProps {
	hasErrors?: boolean;
	disabled: boolean;
	value: string;
	setValue: (value: string) => void;
}

export default function Bitrate({ value: FieldValue, disabled, hasErrors, setValue }: BitrateProps): ReactElement {
	let unit: UnitFieldUnit = KBIT;
	let value: number | string = FieldValue;
	try {
		const result = parseBitrate(value);
		value = result.value;
		unit = result.unit;
	} catch {
		// ignore
	}

	return (
		<ControlWrapper>
			<Input
				value={value}
				onChange={(_rate) => setValue(_rate + unit.value)}
				hasErrors={hasErrors}
				disabled={disabled}
				withUnit
			/>
			<Options value={unit.value} options={BITRATE_UNITS} onChange={(v) => setValue(value + v)} disabled={disabled} />
		</ControlWrapper>
	);
}

const BIT = { value: 'bit', label: 'bit/s' };
const KBIT = { value: 'kbit', label: 'kbit/s' };
const MBIT = { value: 'mbit', label: 'mbit/s' };
const GBIT = { value: 'gbit', label: 'gbit/s' };
const TBIT = { value: 'tbit', label: 'tbit/s' };
const BPS = { value: 'bps', label: 'byte/s' };
const KBPS = { value: 'kbps', label: 'kb/s' };
const MBPS = { value: 'mbps', label: 'mb/s' };
const GBPS = { value: 'gbps', label: 'gb/s' };
const TBPS = { value: 'tbps', label: 'tb/s' };
const BITRATE_UNITS: UnitFieldUnit[] = [BIT, KBIT, MBIT, GBIT, TBIT, BPS, KBPS, MBPS, GBPS, TBPS];

function parseBitrate(value: string): { unit: UnitFieldUnit; value: number } {
	if (value) {
		const m = /^([+-]?\d+)([a-zA-Z]{0,4})$/.exec(value);
		if (m) {
			return { value: Number(m[1] ?? 0), unit: BITRATE_UNITS.find((u) => u.value === m[2]) ?? KBIT };
		}
		throw new Error(`Invalid bitrate value: ${value}`);
	}
	return { value: 0, unit: KBIT };
}
