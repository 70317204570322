/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Text } from '@steadybit/ui-components-lib';
import { NotificationKeyVO } from 'ui-api';
import { ReactElement } from 'react';

import { Link } from '../Link';

export interface ContentProps {
	key: NotificationKeyVO;
	data?: { [index: string]: unknown };
}

export interface Content {
	title?: string;
	message: string | ReactElement;
	level: 'info' | 'warning' | 'error';
}

const defaultContent: Content = {
	title: 'Limit reached',
	message: 'You reached your licensed limit{{USAGE_RATIO}}. Get in contact with us to increase the threshold.',
	level: 'warning',
};

const contentMap: Record<NotificationKeyVO, Content> = {
	LICENSE_SOFT_LIMIT_REACHED_AGENT_SIZE: {
		title: 'Agents limit reached',
		message: (
			<Text>
				You can still continue connecting agents, please{' '}
				<Link href="https://steadybit.com/contact-us/" external>
					contact us
				</Link>{' '}
				if you want to update your license.
			</Text>
		),
		level: 'warning',
	},
	LICENSE_HARD_LIMIT_REACHED_AGENT_SIZE: {
		title: 'Agents limit reached',
		message: `You reached your limit of agents. You can't register any more at this time.${''}`,
		level: 'error',
	},
	LICENSE_SOFT_LIMIT_REACHED_ENVIRONMENT_SIZE: {
		title: 'Environments limit reached',
		message: (
			<Text>
				You can still continue creating new environments, please{' '}
				<Link href="https://steadybit.com/contact-us/" external>
					contact us
				</Link>{' '}
				if you want to update your license.
			</Text>
		),
		level: 'warning',
	},
	LICENSE_HARD_LIMIT_REACHED_ENVIRONMENT_SIZE: {
		title: 'Environments limit reached',
		message: `You reached your limit for environments. You can't create any more at this time.${''}`,
		level: 'error',
	},
	LICENSE_SOFT_LIMIT_REACHED_TEAM_SIZE: {
		title: 'Teams limit reached',
		message: (
			<Text>
				You can still continue creating teams, please{' '}
				<Link href="https://steadybit.com/contact-us/" external>
					contact us
				</Link>{' '}
				if you want to update your license.
			</Text>
		),
		level: 'warning',
	},
	LICENSE_HARD_LIMIT_REACHED_TEAM_SIZE: {
		title: 'Teams limit reached',
		message: `You reached your limit for teams. You can't create any more at this time.${''}`,
		level: 'error',
	},
	LICENSE_HARD_LIMIT_REACHED_TEAM_SIZE_LDAP_SYNC: {
		title: 'Team limit reached',
		message: 'The LDAP synchronization could not create {{newTeams}} team(s) as your team limit would be exceeded.',
		level: 'error',
	},
	LICENSE_HARD_LIMIT_REACHED_TEAM_SIZE_OIDC_SYNC: {
		title: 'Team limit reached',
		message: 'The team {{team}} could not be created for invited user {{user}} as your team limit would be exceeded.',
		level: 'error',
	},
	LICENSE_SOFT_LIMIT_REACHED_USER_SIZE: {
		title: 'Full members limit reached',
		message: (
			<Text>
				You can still continue adding users to teams, please{' '}
				<Link href="https://steadybit.com/contact-us/" external>
					contact us
				</Link>{' '}
				if you want to update your license.
			</Text>
		),
		level: 'warning',
	},
	LICENSE_HARD_LIMIT_REACHED_USER_SIZE: {
		title: 'Full members limit reached',
		message: `You reached your limit for full members. You can't create any more at this time.${''}`,
		level: 'error',
	},
	LICENSE_HARD_LIMIT_REACHED_USER_SIZE_LDAP_SYNC: {
		title: 'Full members limit reached',
		message:
			'The LDAP synchronization could not assign {{newUsers}} new user(s) as the full members limit would be exceeded.',
		level: 'error',
	},
	LICENSE_HARD_LIMIT_REACHED_USER_SIZE_OIDC_SYNC: {
		title: 'Team invitation has failed',
		message: `The user {{user}} couldn't be added to team {{team}} because you've reached the maximum number of full members.${''}`,
		level: 'error',
	},
	LICENSE_SOFT_LIMIT_REACHED_EXPERIMENT_EXECUTION: {
		title: 'Experiment runs limit reached',
		message: (
			<Text>
				You can still continue running experiments, please{' '}
				<Link href="https://steadybit.com/contact-us/" external>
					contact us
				</Link>{' '}
				if you want to update your license.
			</Text>
		),
		level: 'warning',
	},
	LICENSE_HARD_LIMIT_REACHED_EXPERIMENT_EXECUTION: {
		title: 'Experiment runs limit reached',
		message: 'You can still create new experiments or see past runs, but you can not run any experiment.',
		level: 'error',
	},
};

export default function getContent(notification: ContentProps): Content {
	const content = contentMap[notification.key] || defaultContent;
	if (typeof content.message === 'string' && notification.data) {
		content.message = replaceUsageRatio(notification, content.message);
		content.message = replacePlaceholder(notification, content.message);
	}
	return content;
}

function replaceUsageRatio(notification: ContentProps, message: string): string {
	const usage: number | undefined = typeof notification.data?.usage === 'number' ? notification.data.usage : undefined;
	const limit: number | undefined = typeof notification.data?.limit === 'number' ? notification.data.limit : undefined;

	const usageRatio = usage !== undefined && limit !== undefined ? ` (${usage}/${limit})` : '';
	return message.replaceAll('{{USAGE_RATIO}}', usageRatio);
}

function replacePlaceholder(notification: ContentProps, message: string): string {
	if (notification.data) {
		return Object.keys(notification.data).reduce((current, key) => {
			return current.replaceAll('{{' + key + '}}', notification.data?.[key] + '');
		}, message);
	}
	return message;
}
