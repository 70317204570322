/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ActionVO, EnvironmentSummaryVO, VariableVO, OccuranceVO } from 'ui-api';
import { Pill, Stack, Text, TextField, Tooltip } from 'components';
import Collapsible from 'components/Collapsible.v2/Collapsible.v2';
import Occurances from 'pages/templates/components/Occurances';
import { DataStreamResult } from 'utils/hooks/stream/result';
import { ErrorMessage } from '@steadybit/ui-components-lib';
import Skeletons from 'components/Skeleton/Skeletons';
import { Services } from 'services/services';
import { localeCompare } from 'utils/string';
import { useFormikContext } from 'formik';
import { ReactElement } from 'react';

import EnvironmentSelection from '../EnvironmentSelection';
import { UseTemplateFormData } from '../UseTemplateForm';
import OccurrenceValue from './OccurrenceValue';

interface EnvironmentVariablesProps {
	selectedEnvironment: EnvironmentSummaryVO | undefined;
	actionsResult: DataStreamResult<ActionVO[]>;
	environments: EnvironmentSummaryVO[];
}

export default function EnvironmentVariables({
	selectedEnvironment,
	actionsResult,
	environments,
}: EnvironmentVariablesProps): ReactElement {
	const { values } = useFormikContext<UseTemplateFormData>();
	const { variablesMap } = values;

	return (
		<Stack size="large">
			<EnvironmentSelection selectedEnvironment={selectedEnvironment} environments={environments} />

			{variablesMap.size > 0 && (
				<Stack size="small">
					<Stack size="xxSmall">
						<Text variant="medium" color="neutral600">
							This template uses environment variables. Environment variables are placeholders that can be used in
							various places in an experiment design. Their values are defined per environment. You can find the
							environment variables used by this template and the values they resolve to in the list below.
						</Text>
					</Stack>
					<Variables actionsResult={actionsResult} />
				</Stack>
			)}
		</Stack>
	);
}

interface VariablesProps {
	actionsResult: DataStreamResult<ActionVO[]>;
}

function Variables({ actionsResult }: VariablesProps): ReactElement {
	const formik = useFormikContext<UseTemplateFormData>();
	const { environmentId, variablesMap, variableValuesMap } = formik.values;

	const environmentVariablesResult = Services.environments.useEnvironmentVariables$(environmentId);
	if (environmentVariablesResult.loading || !environmentVariablesResult.value) {
		return (
			<>
				{new Array(variablesMap.size).fill(0).map((_, index) => (
					<Collapsible
						key={`${index}-collapsed`}
						borderless
						backgroundColorExpanded="neutral100"
						title={<Skeletons widths={[Math.min(250, Math.max(50, Math.random() * 10))]} height={32} />}
					>
						<></>
					</Collapsible>
				))}
			</>
		);
	}

	const variablesList: EnvironmentVariable[] = Array.from(variablesMap.entries())
		.map(([key, occurances]) => {
			const environmentVariable = environmentVariablesResult.value.find((variable) => variable.key === key);
			return {
				key,
				occurances,
				environmentVariable,
			};
		})
		.sort((a, b) => {
			if (a.environmentVariable && !b.environmentVariable) {
				return 1;
			}
			if (!a.environmentVariable && b.environmentVariable) {
				return -1;
			}
			return localeCompare(a.key, b.key);
		});

	return (
		<>
			{variablesList.map((environmentVariable) => {
				const { key } = environmentVariable;
				return (
					<EnvironmentVariableSection
						key={key}
						actionsResult={actionsResult}
						environmentVariable={environmentVariable}
						value={variableValuesMap.get(key) || ''}
						setValue={(value) => {
							const clone = new Map(variableValuesMap);
							clone.set(key, value);
							formik.setFieldValue('variableValuesMap', clone);
						}}
					/>
				);
			})}
		</>
	);
}

interface EnvironmentVariable {
	environmentVariable: VariableVO | undefined;
	occurances: OccuranceVO[];
	key: string;
}

interface EnvironmentVariableSectionProps {
	actionsResult: DataStreamResult<ActionVO[]>;
	environmentVariable: EnvironmentVariable;
	value: string;
	setValue: (value: string) => void;
}

function EnvironmentVariableSection({
	environmentVariable,
	actionsResult,
	value,
	setValue,
}: EnvironmentVariableSectionProps): ReactElement {
	const isVariableDefinedInEnvironment = Boolean(environmentVariable.environmentVariable);
	const formik = useFormikContext<UseTemplateFormData>();

	return (
		<Collapsible
			key={`${environmentVariable.key}-expanded`}
			borderless
			backgroundColorExpanded="neutral100"
			initialExpanded={!isVariableDefinedInEnvironment}
			title={
				<Pill
					backgroundColor="purple200"
					color="purple800"
					sx={{
						width: 'fit-content',
						height: '32px',
						borderRadius: '16px',
						px: '16px',
					}}
				>
					<Text variant="mediumMedium" lineHeight="15px">{`{{${environmentVariable.key}}}`}</Text>
				</Pill>
			}
		>
			<div
				style={{
					display: 'grid',
					gridTemplateColumns: '400px 1fr',
					gap: '32px',
				}}
			>
				<Stack size="xSmall">
					<Text variant="smallStrong" color="neutral600">
						Value
					</Text>
					{isVariableDefinedInEnvironment ? (
						<Tooltip content="This variable is already defined in the selected environment and therefore cannot be changed here.">
							<TextField
								value={environmentVariable.environmentVariable?.value}
								type="text"
								disabled
								sx={{ borderColor: 'neutral200', borderWidth: '1px' }}
							/>
						</Tooltip>
					) : (
						<>
							<OccurrenceValue value={value} setValue={setValue} occurances={environmentVariable.occurances} />
							<Tooltip
								content={
									<>
										You can define the environment variable now, making it available to all experiments in the selected
										environment, or decide to do it once you have created the experiment.
									</>
								}
								bindWidth={{
									target: 'reference',
									offset: 105,
								}}
							>
								<span>
									<ErrorMessage type="small" level="warning" withIcon>
										This variable is not defined in the selected environment
									</ErrorMessage>
								</span>
							</Tooltip>
						</>
					)}
				</Stack>

				<Occurances
					environmentVariable={environmentVariable.key}
					occurances={environmentVariable.occurances}
					lanes={formik.values.__originalLanes}
					actionsResult={actionsResult}
					entityName="variable"
				/>
			</div>
		</Collapsible>
	);
}
