/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { getApplyShortcutTooltip } from 'components/PredicateEditor/PredicateEditor';
import { Button, Container, Stack, Text, Tooltip } from 'components';
import { ReactElement, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import ShowAdviceToggle from 'targets/ShowAdviceToggle';
import { usePromise } from 'utils/hooks/usePromise';
import Editor from 'components/Editor/Editor';
import { IconHandle } from 'components/icons';
import { useUrlState } from 'url/useUrlState';
import { Services } from 'services/services';
import { useTeam } from 'services/useTeam';
import { useFormikContext } from 'formik';
import { theme } from 'styles.v2/theme';
import { TeamSummaryVO } from 'ui-api';
import Sidebar from 'targets/Sidebar';

import {
	adviceParam,
	colorConfigParam,
	colorOverridesParam,
	coloringParam,
	descriptionParam,
	environmentParam,
	filterQueryParam,
	groupingConfigsParam,
	groupingParam,
	nameParam,
	sizingParam,
} from '../urlParams';
import { useViewConfiguredTracking } from '../hooks/useLandscapeExploreTracking';
import ConfigureGroupingModal from './ConfigureGroupingModal';
import { GroupConfig, LandscapeConfig } from '../types';
import ConfigureColorModal from './ConfigureColorModal';
import DropDown, { LoadingDropDown } from './DropDown';
import { getGroupingLabelFromUrl } from '../utils';
import DropDownLabel from './DropDownLabel';
import { emit } from '../ServiceLocator';
import { ColorList } from './ColorList';

export default function ConfigSidebar(): ReactElement {
	useViewConfiguredTracking();

	const team = useTeam();
	const [configureGroupingIndex, setConfigureGroupingIndex] = useState<number | null>(null);
	const [showColorConfigModal, setShowColorConfigModal] = useState(false);

	const formik = useFormikContext<LandscapeConfig>();
	const {
		colorOverrides,
		colorMapping,
		groupConfigs,
		colorConfig,
		showAdvice,
		groupBy,
		colorBy,
		sizeBy,
		teamId,
		environmentId,
		filterQuery,
	} = formik.values;

	const readonly = teamId !== team.id;

	const attributeKeysResult = usePromise(
		() => Services.editorApi.fetchTargetAttributeKeys({ environmentId, predicate: { query: filterQuery } }),
		[environmentId, filterQuery],
	);
	const attributeKeys = attributeKeysResult?.value || [];
	const isLoading = !!attributeKeysResult.loading;

	const [, , updateUrlWithState] = useUrlState([
		groupingConfigsParam,
		colorOverridesParam,
		colorConfigParam,
		environmentParam,
		descriptionParam,
		coloringParam,
		groupingParam,
		adviceParam,
		sizingParam,
		nameParam,
	]);

	useEffect(() => {
		emit({ type: 'advice', active: showAdvice });
	}, [showAdvice]);

	const onDragEnd = (result: DropResult): void => {
		if (result.destination) {
			const newGrouping = reorder<string>(groupBy, result.source.index, result.destination.index);
			const newGroupConfigs = reorder<GroupConfig>(groupConfigs, result.source.index, result.destination.index);

			formik.setFieldValue('groupBy', newGrouping);
			formik.setFieldValue('groupConfigs', newGroupConfigs);
			updateUrlWithState({ grouping: newGrouping, groupConfigs: newGroupConfigs });
		}
	};

	return (
		<>
			{configureGroupingIndex !== null && (
				<ConfigureGroupingModal
					index={configureGroupingIndex}
					attributeKey={groupBy[configureGroupingIndex]}
					onClose={() => setConfigureGroupingIndex(null)}
					onSave={(newGroupConfigs) => {
						formik.setFieldValue('groupConfigs', newGroupConfigs);
						updateUrlWithState({ groupConfigs: newGroupConfigs });
					}}
					readOnly={readonly}
				/>
			)}
			{showColorConfigModal && (
				<ConfigureColorModal
					attributeKey={colorBy}
					onClose={() => setShowColorConfigModal(false)}
					onSave={(colorConfig) => {
						formik.setFieldValue('colorConfig', colorConfig);
						formik.setFieldValue('colorOverrides', colorConfig ? colorOverrides : null);
						updateUrlWithState({ colorConfig, colorOverrides: colorConfig ? colorOverrides : null });
					}}
					readOnly={readonly}
				/>
			)}
			<Sidebar
				title="Landscape"
				sx={{
					bg: 'neutral000',
					px: 'small',
				}}
			>
				{(collapsed) => {
					if (collapsed) {
						return null;
					}

					return (
						<AnimatePresence initial>
							<motion.div
								initial={{ opacity: 0 }}
								exit={{ opacity: 1 }}
								animate={{ opacity: 1 }}
								transition={{ delay: 0.125 }}
							>
								<Stack mt="small" pb="small">
									<ShowAdviceToggle
										checked={showAdvice}
										readOnly={readonly}
										theme="dark"
										withLegend
										onChange={(checked) => {
											formik.setFieldValue('showAdvice', checked);
											updateUrlWithState({ showAdvice: checked });
										}}
									/>

									<EditorContent team={team} />

									{isLoading ? (
										<>
											{groupBy.map((_, i) => (
												<LoadingDropDown key={i} label="Group by" />
											))}
											<LoadingDropDown label="Add group by" />
											<LoadingDropDown label="Size by" />
											<LoadingDropDown label="Color by" />
										</>
									) : (
										<>
											{groupBy.length === 0 && (
												<DropDown
													readonly={readonly}
													heading="Add group by"
													label={getGroupLabel()}
													placeholder="Type to search..."
													items={attributeKeys}
													onItemClick={(key) => {
														const newGrouping = key ? [getGroupingLabelForUrl(key)] : [];
														const newGroupConfigs = key ? [null] : [];
														formik.setFieldValue('groupBy', newGrouping);
														formik.setFieldValue('groupConfigs', newGroupConfigs);
														updateUrlWithState({ grouping: newGrouping, groupConfigs: newGroupConfigs });
													}}
												/>
											)}

											{groupBy.length > 0 && (
												<Stack
													size="none"
													style={{
														marginTop: 0, // override the stack behaviour
													}}
												>
													<DragDropContext onDragEnd={onDragEnd}>
														<Droppable droppableId="droppable">
															{(provided) => (
																<div {...provided.droppableProps} ref={provided.innerRef}>
																	{groupBy.map((_groupBy, i) => {
																		const isConfigDefinedForGroup = groupConfigs[i] !== null;
																		return (
																			<Draggable key={`${_groupBy}-${i}`} index={i} draggableId={`${_groupBy}-${i}`}>
																				{(provided) => (
																					<div
																						ref={provided.innerRef}
																						{...provided.draggableProps}
																						style={{
																							...provided.draggableProps.style,
																							marginTop: 16,
																						}}
																					>
																						<DropDown
																							framed={!!isConfigDefinedForGroup}
																							key={`${_groupBy}-${i}`}
																							readonly={readonly}
																							heading={
																								<Stack direction="horizontal" alignItems="center" size="none">
																									<div {...provided.dragHandleProps}>
																										{groupBy.length > 1 && (
																											<IconHandle variant="small" color="neutral500" mx="xxSmall" />
																										)}
																									</div>
																									{isConfigDefinedForGroup || !readonly ? (
																										<DropDownLabel
																											label="Group by"
																											configLabel={
																												isConfigDefinedForGroup
																													? readonly
																														? 'Show Attribute Config…'
																														: 'Edit Attribute Config…'
																													: 'Attribute Configuration'
																											}
																											canEdit={isConfigDefinedForGroup && !readonly}
																											onConfigureClick={() => setConfigureGroupingIndex(i)}
																										/>
																									) : (
																										<Text variant="smallStrong">Group by</Text>
																									)}
																								</Stack>
																							}
																							label={getGroupLabel(_groupBy)}
																							placeholder="Type to search..."
																							items={attributeKeys}
																							onItemClick={(key) => {
																								const newGrouping = key
																									? [
																											...groupBy.slice(0, i),
																											getGroupingLabelForUrl(key),
																											...groupBy.slice(i + 1),
																										]
																									: [...groupBy.slice(0, i), ...groupBy.slice(i + 1)];
																								const newGroupConfigs = key
																									? [...groupConfigs.slice(0, i), null, ...groupConfigs.slice(i + 1)]
																									: [...groupConfigs.slice(0, i), ...groupConfigs.slice(i + 1)];

																								formik.setFieldValue('groupBy', newGrouping);
																								formik.setFieldValue('groupConfigs', newGroupConfigs);
																								updateUrlWithState({
																									grouping: newGrouping,
																									groupConfigs: newGroupConfigs,
																								});
																							}}
																						/>
																					</div>
																				)}
																			</Draggable>
																		);
																	})}
																	{provided.placeholder}
																</div>
															)}
														</Droppable>
													</DragDropContext>
												</Stack>
											)}

											{groupBy.length > 0 && (
												<DropDown
													key="add-group-by"
													heading="Add group by"
													label={getGroupLabel()}
													placeholder="Type to search..."
													readonly={readonly}
													items={attributeKeys}
													onItemClick={(key) => {
														const newGrouping = key
															? [...groupBy, getGroupingLabelForUrl(key)]
															: groupBy.slice(0, groupBy.length);
														const newGroupConfigs = key
															? [...groupConfigs, null]
															: groupConfigs.slice(0, groupConfigs.length);

														formik.setFieldValue('groupBy', newGrouping);
														formik.setFieldValue('groupConfigs', newGroupConfigs);
														updateUrlWithState({ grouping: newGrouping, groupConfigs: newGroupConfigs });
													}}
												/>
											)}

											<DropDown
												heading="Size by"
												label={sizeBy}
												placeholder="Type to search..."
												readonly={readonly}
												items={attributeKeys}
												onItemClick={(key) => {
													formik.setFieldValue('sizeBy', key || '');
													updateUrlWithState({ sizing: key || '' });
												}}
											/>

											<DropDown
												framed={!!colorConfig}
												heading={
													colorConfig || !readonly ? (
														<DropDownLabel
															label="Color by"
															configLabel={
																colorConfig
																	? readonly
																		? 'Show Attribute Config…'
																		: 'Edit Attribute Config…'
																	: 'Attribute Configuration'
															}
															canEdit={!!colorConfig && !readonly}
															onConfigureClick={colorBy ? () => setShowColorConfigModal(true) : undefined}
														/>
													) : (
														<Text variant="smallStrong">Color by</Text>
													)
												}
												label={colorBy}
												placeholder="Type to search..."
												readonly={readonly}
												items={attributeKeys}
												onItemClick={(key) => {
													formik.setFieldValue('colorBy', key || '');
													formik.setFieldValue('colorConfig', null);
													formik.setFieldValue('colorOverrides', null);
													updateUrlWithState({ coloring: key || '', colorConfig: null, colorOverrides: null });
												}}
											/>
										</>
									)}

									{colorMapping && colorMapping.colorList.length > 0 && (
										<ColorList
											colorMapping={colorMapping}
											setColorOverrides={(newColorOverrides) => {
												formik.setFieldValue('colorOverrides', newColorOverrides);
												updateUrlWithState({ colorOverrides: newColorOverrides });
											}}
											colorConfig={colorConfig}
											colorBy={colorBy}
											readOnly={readonly}
										/>
									)}
								</Stack>
							</motion.div>
						</AnimatePresence>
					);
				}}
			</Sidebar>
		</>
	);
}

function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result;
}

function getGroupLabel(grouping?: string): string {
	if (!grouping) {
		return '';
	}
	return getGroupingLabelFromUrl(grouping);
}

function getGroupingLabelForUrl(key: string): string {
	return key;
}

function EditorContent({ team }: { team: TeamSummaryVO }): ReactElement {
	const formik = useFormikContext<LandscapeConfig>();
	const { filterQuery, teamId } = formik.values;

	const [tempFilterQuery, setTempFilterQuery] = useState(filterQuery);

	// this is needed because the editor does not update when the query changes (because it is a controlled component)
	const [forceUpdateSignal, setForceUpdateSignal] = useState(0);
	useEffect(() => {
		setForceUpdateSignal((s) => s + 1);
	}, [tempFilterQuery]);

	const [, , updateUrlWithState] = useUrlState([filterQueryParam]);

	const readonly = teamId !== team.id;
	const showApplyButton = filterQuery !== tempFilterQuery;

	return (
		<Stack
			size="none"
			sx={{ borderBottom: '1px solid', borderColor: theme.colors.neutral300, paddingBottom: 'medium' }}
		>
			<Container px="small" pt="small" bg="neutral100">
				<Text variant="smallStrong">Filter your view via query</Text>
			</Container>

			<Editor
				disabled={readonly}
				maxHeight={200}
				forceUpdateSignal={forceUpdateSignal}
				initialValue={tempFilterQuery}
				debounceInputTime={1000}
				onValidCodeChanged={(codeString) => {
					if (!readonly && tempFilterQuery.trim() !== codeString.trim()) {
						setTempFilterQuery(codeString);
					}
				}}
				onApply={() => {
					if (showApplyButton) {
						formik.setFieldValue('filterQuery', tempFilterQuery);
						updateUrlWithState({ query: tempFilterQuery });
					}
				}}
			/>

			{showApplyButton && (
				<Container display="flex" pt="xxSmall" justifyContent="flex-end" width="100%">
					<Tooltip content={getApplyShortcutTooltip()}>
						<Button
							variant="primarySmall"
							onClick={() => {
								formik.setFieldValue('filterQuery', tempFilterQuery);
								updateUrlWithState({ query: tempFilterQuery });
							}}
						>
							Apply
						</Button>
					</Tooltip>
				</Container>
			)}
		</Stack>
	);
}
