/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { onTenant, onTitleChange as onTitleChangeForInstana, onUser } from 'tracking/instana';
import GlobalNotificationBanner from 'components/Notifications/GlobalNotificationBanner';
import AgentInstallBanner from 'components/AgentInstallBanner/AgentInstallBanner';
import DropDownButton from 'components/Select/Dropdown/DropdownButton';
import Onboarding from 'pages/onboarding/OnboardingLazyLoader';
import { forceReloadTeams } from 'services/team/useActiveTeam';
import { useTenantSwitcher } from 'tenancy/useTenantSwitcher';
import SplashScreen from 'pages/splashScreen/splashScreen';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import useObservable from 'react-use/lib/useObservable';
import useAgentReport from 'utils/hooks/useAgentReport';
import React, { ReactElement, useEffect } from 'react';
import { Redirect, Route, Switch } from 'url/router';
import { useAmplitude } from 'tracking/amplitude';
import { useLicense } from 'services/licenseApi';
import ExperimentsV2 from 'pages/experimentsV2';
import LandscapeViewer from 'pages/landscape';
import useLogRocket from 'tracking/logRocket';
import { TenantMenu } from 'pages/tenantMenu';
import { useTenant } from 'tenancy/useTenant';
import { Services } from 'services/services';
import useIntercom from 'tracking/intercom';
import { IconLogo } from 'components/icons';
import { useUser } from 'services/usersApi';
import { isUserAdmin } from 'utils/user';
import { Container } from 'components';
import { Helmet } from 'react-helmet';
import { OnboardingVO } from 'ui-api';
import { ampli } from 'ampli';
import { EMPTY } from 'rxjs';

import SteadybitUserOnProd from './components/SteadybitUserOnProd/SteadybitUserOnProd';
import KillswitchWarning from './components/KillswitchWarning/KillswitchWarning';
import UserQuestionaire from './components/UserQuestionaire/UserQuestionaire';
import LicenseWarning from './components/LicenseWarning/LicenseWarning';
import MainNavigation from './components/MainNavigation/MainNavigation';
import UpdatedWarning from './components/UpdatedWarning/UpdatedWarning';
import SafariBrowserWarning from './components/SafariBrowserWarning';
import useDeletedTeamRedirect from './hooks/useDeletedTeamRedirect';
import { ApplicationHeaderHeight } from './ApplicationHeaderHeight';
import SystemStatus from './components/SystemStatus/SystemStatus';
import PlatformSetup from '../pages/settings/platformSetup';
import PermalinkRedirects from './PermalinkRedirects';
import Dashboard from '../pages/dashboard';
import Settings from '../pages/settings';

interface AppProps {
	fetchOnboarding: () => void;
	onboarding: OnboardingVO;
}

export default function App({ onboarding, fetchOnboarding }: AppProps): ReactElement {
	const tenant = useTenant();
	const license = useLicense();
	const user = useUser();

	const isAdmin = isUserAdmin(tenant.user);

	const killswitchActive = useObservable(tenant.initialized ? Services.killswitch.killswitchActive$ : EMPTY);

	const amplitudeInitialized = useAmplitude();
	useLogRocket();
	useIntercom();
	useDeletedTeamRedirect();

	useEffect(() => onUser(user), [user]);
	useEffect(() => onTenant(tenant), [tenant]);
	const { someAgentHasReportedInThePast, loading: agentReportLoading } = useAgentReport();

	const [dbExportAvailable] = useAsyncState(() => Services.exportApi.isDbExportAvailable());
	const [metricExportAvailable] = useAsyncState(() => Services.exportApi.isMetricExportAvailable());
	const [amplitudeExportAvailable] = useAsyncState(() => Services.exportApi.isAmplitudeExportAvailable());

	if (!amplitudeInitialized || agentReportLoading) {
		return <SplashScreen />;
	}

	if (user.questionaireState === 'NOT_SEEN' || !user.termsAccepted) {
		return (
			<Switch>
				<Route path="/userQuestionaire/:page">
					{({ match }) => {
						const page = Number(match?.params.page ?? 1);
						return <UserQuestionaire user={user} page={page} />;
					}}
				</Route>
				<Route path="/">
					<Redirect to="/userQuestionaire/1" />
				</Route>
			</Switch>
		);
	}

	return (
		<ApplicationHeaderHeight>
			<Switch>
				<Route path="/onboarding">
					<Container
						sx={{
							display: 'flex',
							flexDirection: 'column',

							width: '100vw',
							height: '100vh',
							overflow: 'hidden',
						}}
					>
						<Container>
							<SafariBrowserWarning />
							<SteadybitUserOnProd />
							<SystemStatus />
							<LicenseWarning admin={isAdmin} expiry={license?.expires} />
						</Container>
						<Onboarding
							onComplete={async () => {
								await Services.dashboard.setOnboardingStatusComplete(true);
								await fetchOnboarding();
								forceReloadTeams();
								await ampli.client.flush();
								window.location.href = '/';
							}}
						/>
					</Container>
				</Route>

				{onboarding && !onboarding.onboardingCompleted && (
					<Route path={'/'}>
						<Redirect to={'/onboarding/agents'} />
					</Route>
				)}

				<Route path={'/permalink/'}>
					<PermalinkRedirects />
				</Route>

				<Route path={['/dashboard/', '/experiments/', '/landscape', '*']}>
					{(match) => {
						const isSettings = match.location.pathname.startsWith('/settings');
						return (
							<div style={{ height: '100vh' }}>
								<div
									style={{
										position: 'fixed',
										top: 0,
										bottom: 0,
										left: 0,
										width: isSettings ? '160px' : '80px',
										zIndex: 1,
									}}
								>
									<MainNavigation
										templatesAvailable={
											license?.features.find((feature) => feature.name === 'TEMPLATES') ? true : false
										}
										amplitudeExportAvailable={!!amplitudeExportAvailable.value}
										metricExportAvailable={!!metricExportAvailable.value}
										dbExportAvailable={!!dbExportAvailable.value}
										activePath={match.location.pathname}
										withSettingsNavigation={isSettings}
									/>
								</div>

								<Container
									sx={{
										position: 'fixed',
										top: 0,
										bottom: 0,
										left: isSettings ? '160px' : '80px',
										right: 0,
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<Helmet
										onChangeClientState={(newState) => {
											const title = Array.isArray(newState.title) ? newState.title[0] + '-key-' : newState.title;

											onTitleChangeForInstana(title);
										}}
									>
										<title>steadybit</title>
									</Helmet>
									<SafariBrowserWarning />
									<UpdatedWarning />
									<GlobalNotificationBanner />
									<SteadybitUserOnProd />
									<SystemStatus />
									<LicenseWarning admin={isAdmin} expiry={license?.expires} />
									{killswitchActive && <KillswitchWarning />}
									<Container
										sx={{
											height: '100%',
											overflow: 'hidden',
											display: 'grid',
											gridTemplateColumns: 'auto 0',
											gridTemplateRows: 'auto',
											gridTemplateAreas: '"main sidebar"',
										}}
									>
										{!tenant.initialized ? (
											<SetupPagesOnly />
										) : (
											<AllPages
												pathname={match.location.pathname}
												isAdmin={isAdmin}
												someAgentHasReportedInThePast={someAgentHasReportedInThePast}
											/>
										)}
									</Container>
								</Container>
							</div>
						);
					}}
				</Route>
			</Switch>
		</ApplicationHeaderHeight>
	);
}

const SetupPagesOnly: React.VFC = () => {
	const tenant = useTenant();
	const [tenants] = useAsyncState(() => Services.tenants.fetchTenants(), [], []);
	const tenantSwitcher = useTenantSwitcher();
	return (
		<Container as={'main'} sx={{ gridArea: 'main', overflow: 'auto', position: 'relative' }}>
			{!tenants.loading && tenants?.value.length > 1 && (
				<DropDownButton
					ml="medium"
					value={`/ ${tenant.name}`}
					onValueChanged={tenantSwitcher}
					variant="onboardingNav"
					icon={<IconLogo />}
				>
					{({ selectItem }) => <TenantMenu onSelect={(t) => selectItem(t.key)} />}
				</DropDownButton>
			)}
			<PlatformSetup />
		</Container>
	);
};

const AllPages: React.VFC<{
	someAgentHasReportedInThePast: boolean;
	isAdmin: boolean;
	pathname: string;
}> = ({ isAdmin, pathname, someAgentHasReportedInThePast }) => {
	const isDashboard = pathname.startsWith('/dashboard');

	return (
		<>
			<Switch>
				<Route path={'/dashboard'}>
					<Helmet>
						<title>Dashboard</title>
					</Helmet>
					<Dashboard renderAgentInstallBanner={!someAgentHasReportedInThePast} />
				</Route>
				<Route path={'/landscape'}>
					<Helmet>
						<title>Explorer</title>
					</Helmet>
					<LandscapeViewer />
				</Route>
				<Route path={['/experiments', '/experimentruns']}>
					<Helmet>
						<title>Experiments</title>
					</Helmet>
					<ExperimentsV2 />
				</Route>

				<Route path={'/settings'}>
					<Settings someAgentHasReported={someAgentHasReportedInThePast} isAdmin={isAdmin} />
				</Route>
				<Route path={'/'}>
					<Redirect to={'/dashboard'} />
				</Route>
			</Switch>
			{!someAgentHasReportedInThePast && !isDashboard && (
				<AgentInstallBanner
					sx={{
						position: 'absolute',
						zIndex: 1,
						bottom: 16,
						right: 16,
					}}
				/>
			)}
		</>
	);
};
