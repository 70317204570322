/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import EnvironmentSelector from 'components/EnvironmentSelector/EnvironmentSelector';
import { useViewsUpdateSignal } from 'pages/customViews/hooks/useViewsUpdateSignal';
import LicenseTooltipContent from 'App/components/LicenseTooltipContent';
import DropDownButton from 'components/Select/Dropdown/DropdownButton';
import { Container, RouterButton, Text, Tooltip } from 'components';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { useLicenseFeature } from 'services/licenseApi';
import { usePromise } from 'utils/hooks/usePromise';
import { Flex } from '@steadybit/ui-components-lib';
import { Services } from 'services/services';
import { useTeam } from 'services/useTeam';
import { LandscapeViewVO } from 'ui-api';
import { theme } from 'styles.v2/theme';
import { useHistory } from 'url/hooks';
import { Helmet } from 'react-helmet';

import ExploreViewsContent from './ExploreViewsContent';

interface LandscapeHeaderProps {
	selectedEnvironmentId?: string;
	selectedViewId?: string | null;
	isCustomViews?: boolean;
	isExplore?: boolean;
	isAdvice?: boolean;
	isTable?: boolean;
}

export default function LandscapeHeader({
	selectedEnvironmentId,
	isCustomViews = false,
	isExplore = false,
	isAdvice = false,
	isTable = false,
	selectedViewId,
}: LandscapeHeaderProps): ReactElement {
	const { push } = useHistory();
	const landscapeEnabled = useLicenseFeature('LANDSCAPE');
	const adviceEnabled = useLicenseFeature('RELIABILITY_ADVICE');
	const team = useTeam();

	async function onChangeEnvironment(environmentId: string): Promise<void> {
		if (isAdvice) {
			push(`/landscape/${environmentId}/advice`);
		} else {
			push(`/landscape/${environmentId}/table`);
		}
	}

	return (
		<Container
			sx={{
				display: 'grid',
				gridTemplateColumns: '1fr 1fr 1fr',

				backgroundColor: 'neutral700',
				maxHeight: 72,
				minHeight: 72,
				px: 'medium',
				py: 'xSmall',
			}}
		>
			<Container display="flex" alignItems="center">
				{!isExplore && !isCustomViews && (
					<EnvironmentSelector
						selectedEnvironmentId={selectedEnvironmentId}
						isLandscapeView={isTable}
						teamId={team.id}
						selectEnvironmentId={onChangeEnvironment}
					/>
				)}
			</Container>

			<Container display="flex" alignItems="center" justifyContent="center">
				<Tooltip content={<LicenseTooltipContent />} disabled={!!landscapeEnabled}>
					<Flex direction="horizontal">
						<ViewsButton
							selectedEnvironmentId={selectedEnvironmentId}
							isActive={isExplore || isCustomViews}
							selectedViewId={selectedViewId}
							disabled={!landscapeEnabled}
						/>
					</Flex>
				</Tooltip>

				<RouterButton
					variant={isTable ? 'landscapeHeaderPrimary' : 'landscapeHeader'}
					sx={{ borderRadius: 0, borderRight: 0, px: 'large' }}
					to={`/landscape/${selectedEnvironmentId}/table`}
				>
					Targets
				</RouterButton>

				<Tooltip content={<LicenseTooltipContent />} disabled={!!adviceEnabled}>
					<RouterButton
						variant={isAdvice ? 'landscapeHeaderPrimary' : 'landscapeHeader'}
						sx={{ borderRadius: '0 4px 4px 0', px: 'large' }}
						to={`/landscape/${selectedEnvironmentId}/advice`}
						disabled={!adviceEnabled}
					>
						Advice
					</RouterButton>
				</Tooltip>
			</Container>
		</Container>
	);
}

interface ViewsButtonProps {
	selectedViewId?: string | null;
	selectedEnvironmentId?: string;
	disabled?: boolean;
	isActive: boolean;
}

function ViewsButton({ isActive, disabled, selectedViewId, selectedEnvironmentId }: ViewsButtonProps): ReactElement {
	const team = useTeam();

	const viewResponse = usePromise(() => {
		if (!selectedViewId || !selectedEnvironmentId) {
			return undefined;
		}
		return Services.landscapeV2.getView(selectedViewId, team.id, selectedEnvironmentId);
	}, [selectedViewId, selectedEnvironmentId]);
	const selectedView = viewResponse.value;

	return (
		<>
			{selectedView?.name && (
				<Helmet>
					<title>Explorer / {selectedView.name}</title>
				</Helmet>
			)}

			<RouterButton
				variant={isActive ? 'landscapeHeaderPrimary' : 'landscapeHeader'}
				sx={{
					borderRadius: '4px 0 0 4px',
					borderRight: isActive ? '1px solid ' + theme.colors.neutral500 : 'none',
					maxWidth: 180,
					px: 'medium',
				}}
				disabled={disabled}
				to={selectedView ? `/landscape/explore/${selectedView.id}` : '/landscape/explore/<new>'}
			>
				<Tooltip content={selectedView?.name ?? ''}>
					<Text variant="smallStrong" sx={{ ...textEllipsis }}>
						{selectedView?.name || 'Landscape'}
					</Text>
				</Tooltip>
			</RouterButton>
			<DropDownButton
				variant={isActive ? 'landscapeHeaderPrimary' : 'landscapeHeader'}
				placement="bottom-end"
				disabled={disabled}
				sx={{
					pr: 'xSmall',
					pl: 'xxSmall',
					borderRadius: 0,
					borderRight: 0,
				}}
			>
				{({ selectItem }) => {
					return (
						<ViewsDropDownContent teamId={team.id} selectItem={() => selectItem('')} selectedViewId={selectedViewId} />
					);
				}}
			</DropDownButton>
		</>
	);
}

interface ViewsDropDownContentProps {
	selectedViewId?: string | null;
	selectItem: () => void;
	teamId: string;
}

function ViewsDropDownContent({ teamId, selectItem, selectedViewId }: ViewsDropDownContentProps): ReactElement {
	const reloadSignal = useViewsUpdateSignal();
	const viewsResponse = usePromise<LandscapeViewVO[]>(
		() => Services.landscapeV2.getViews(teamId),
		[teamId, reloadSignal],
	);
	const views = viewsResponse.value ?? [];

	const templateViewsResponse = usePromise<LandscapeViewVO[]>(() => Services.landscapeV2.getTemplateViews(), [teamId]);
	const templateViews = templateViewsResponse.value ?? [];

	return (
		<ExploreViewsContent
			selectedViewId={selectedViewId}
			templateViews={templateViews}
			onChangeView={selectItem}
			views={views}
		/>
	);
}
