/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	BreadcrumbItem,
	Breadcrumbs,
	Button,
	ButtonRun,
	HeaderBar,
	Heading,
	LoadingIndicator,
	SegmentedControl,
	Snackbar,
	Stack,
	Tooltip,
	TutorialTooltip,
	userConfirm,
} from 'components';
import { useExperimentExecutionStatus } from 'pages/experiments/useExperimentExecutionSystemStatusCheck';
import { useExperimentExecutionTrigger } from 'pages/experiments/useExperimentExecutionTrigger';
import { ExperimentExecutionVO, ExperimentStepActionVO } from 'ui-api';
import EditableLabel from 'components/EditableLabel/EditableLabel';
import { IconExperiment, IconStart } from 'components/icons';
import { KillSwitchTooltip } from 'hocs/KillSwitchTooltip';
import { ReactElement, useEffect, useState } from 'react';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import useObservable from 'react-use/lib/useObservable';
import { useField, useFormikContext } from 'formik';
import { useHistory, useLocation } from 'url/hooks';
import { Services } from 'services/services';
import { useAsyncFn } from 'react-use';
import { TUTORIALS } from 'tutorials';
import { isEmpty } from 'lodash';

import { saveExperiment } from './ExperimentEditorFormHandler';
import { ExperimentFormValues } from './types';
import { validate } from './ValidationHandler';
import { ampli } from '../../ampli';

interface ExperimentHeaderProps {
	experimentExecution?: ExperimentExecutionVO;
	section: 'design' | 'executions';
	experimentExecutionId?: number;
}

export default function ExperimentHeader({ experimentExecution, section }: ExperimentHeaderProps): ReactElement {
	const formik = useFormikContext<ExperimentFormValues>();

	const { experimentKey, teamId, actions } = formik.values;

	const [nameField, meta, { setValue, setTouched }] = useField<string>({ name: 'name' });
	const { value: name } = nameField;

	const experimentKeyLabel = useDefaultedExperimentKey(experimentKey, teamId);
	const isNewExperiment = !experimentKey;

	const history = useHistory();

	const disabled = !actions.includes('save');
	const nameIsInvalid = !!meta.error;

	const location = useLocation<{ createdByWizard?: boolean }>();

	useEffect(() => {
		if (section === 'design') {
			const experimentSteps = formik.values.lanes.flatMap((lane) => lane.steps);
			const experimentStepIds = [
				...new Set(
					experimentSteps.map((step) =>
						step.type === 'action' ? (step as ExperimentStepActionVO).actionId : step.type,
					),
				),
			];
			ampli.experimentViewed({
				experiment_key: experimentKey,
				experiment_name: name,
				experiment_saved: !isNewExperiment,
				environment_id: formik.values.environmentId,
				experiment_steps: experimentStepIds,
				experiment_number_of_steps: 1,
			});
		}
	}, [experimentKey, section]);
	return (
		<HeaderBar
			icon={IconExperiment}
			tagline={
				<Breadcrumbs>
					<BreadcrumbItem variant="small" label="Experiments" to="/experiments" sx={{ color: 'neutral300' }} />
				</Breadcrumbs>
			}
			center={
				<SegmentedControl
					type={'dark'}
					name="number"
					value={{ value: section, label: '' }}
					options={[
						{
							value: 'design',
							label: 'Design',
						},
						{
							value: 'executions',
							label: 'Runs',
							disabled: isNewExperiment,
						},
					]}
					onChange={(option): void => {
						if (option.value === 'design') {
							return history.replace(`/experiments/edit/${experimentKey ?? '<new>'}/design${history.location.search}`);
						}
						return history.replace(
							`/experiments/edit/${experimentKey ?? '<new>'}/executions${history.location.search}`,
						);
					}}
				/>
			}
			title={
				<Stack direction="horizontal" size="xSmall" alignItems="center">
					<Heading variant={'medium'} flexShrink={0}>
						{experimentKeyLabel}
					</Heading>

					<EditableLabel
						value={name}
						placeholder={nameIsInvalid ? 'Name your experiment' : 'Untitled Experiment'}
						onChange={(value) => {
							setValue(value);
							setTouched(true);
						}}
						disabled={disabled}
						withTooltip
						width="100%"
						theme="light"
					/>
				</Stack>
			}
		>
			<RunButton experimentExecution={experimentExecution} />

			{location?.state?.createdByWizard && (
				<TutorialTooltip
					placement="bottom-start"
					showAlways
					light
					hideIcon
					hideSkip
					{...TUTORIALS.experiments.wizardWellDone}
				/>
			)}
		</HeaderBar>
	);
}

function RunButton({
	experimentExecution,
}: {
	experimentExecution: ExperimentExecutionVO | undefined;
}): ReactElement | null {
	const [runPressed, setRunPressed] = useState(false);
	const handleRun = useExperimentExecutionTrigger();

	const ongoingIncidentMessage = useExperimentExecutionStatus();

	const formik = useFormikContext<ExperimentFormValues>();
	const { experimentKey, name, actions, lanes } = formik.values;
	const { touched, errors } = formik;
	const dirty = Object.keys(touched).length > 0;

	const killswitchActive = useObservable(Services.killswitch.killswitchActive$);

	const [stop, handleStopButtonClick] = useAsyncFn((executionId: number) =>
		Services.experiments.cancelExperimentRun(executionId),
	);

	if (experimentExecution && !experimentExecution.ended) {
		return (
			<ButtonRun
				onClick={() => handleStopButtonClick(experimentExecution.id)}
				data-cy={`run-${experimentExecution.name}`}
				started={experimentExecution.started}
				estimatedEnd={experimentExecution.estimatedEnd}
				loading={stop.loading}
				running
			/>
		);
	}

	if (!lanes || lanes.length === 0 || lanes.every((lane) => lane.steps.length === 0)) {
		return (
			<Tooltip content="No steps to be executed. Please add at least one step.">
				<Button variant="primaryRun" disabled width="176px" data-cy="run">
					<IconStart mr="xSmall" /> Run Experiment
				</Button>
			</Tooltip>
		);
	}

	if (killswitchActive) {
		return (
			<Tooltip content={<KillSwitchTooltip />}>
				<Button variant="primaryRun" disabled width="176px" data-cy="run">
					<IconStart mr="xSmall" /> Run Experiment
				</Button>
			</Tooltip>
		);
	}

	if (ongoingIncidentMessage) {
		return (
			<Tooltip placement="bottom-end" content={ongoingIncidentMessage}>
				<Button variant="primaryRun" disabled width="176px" data-cy="run">
					<IconStart mr="xSmall" /> Run Experiment
				</Button>
			</Tooltip>
		);
	}

	const canRunByTeam = actions.includes('run-by-team');
	const canRunByLicense = actions.includes('run-by-license');
	const canRun = canRunByTeam && canRunByLicense;

	if (!canRun) {
		return (
			<Tooltip
				placement="bottom-end"
				content={
					!canRunByTeam
						? 'You need to be a member of the Team to run an experiment.'
						: `You've reached your maximum number of experiment runs.${''}`
				}
			>
				<Button variant="primaryRun" disabled width="176px" data-cy="run">
					<IconStart mr="xSmall" /> Run Experiment
				</Button>
			</Tooltip>
		);
	}

	// if only the name is invalid, we can still run the experiment
	const hasErrors = Object.keys(errors).length > 1 || (Object.keys(errors).length === 1 && !('name' in errors));

	if (hasErrors) {
		return (
			<Tooltip placement="bottom-end" content="There are validation errors in your experiment.">
				<Button variant="primaryRun" disabled width="176px" data-cy="run">
					<IconStart mr="xSmall" /> Run Experiment
				</Button>
			</Tooltip>
		);
	}

	const isNewExperiment = !experimentKey;

	return (
		<Button
			variant="primaryRun"
			width="176px"
			onClick={async () => {
				if (runPressed) {
					return;
				}
				setRunPressed(true);
				let runExperiment = false;
				let values = formik.values;
				let experimentKey = values.experimentKey;

				if (dirty || isNewExperiment) {
					const userSelection = await userConfirm({
						title: 'Unsaved Changes',
						message: `You have unsaved changes. Do you want to save ${experimentKey} ${name ?? ''}?`,
						actions: [
							{ value: 'cancel', label: 'Cancel' },
							{
								value: 'save&run',
								label: 'Save & Run',
								variant: 'primary',
							},
						],
					});
					if (userSelection === 'save&run') {
						const saveResult = await saveExperiment(values);
						if (saveResult === null) {
							setRunPressed(false);
							return;
						}
						const [, saved] = saveResult;
						if (saved === null) {
							setRunPressed(false);
							return;
						}
						experimentKey = saved.experimentKey;
						const experiment = await Services.experiments.fetchExperiment(experimentKey);
						values = { ...values, ...experiment, experimentKey };
						formik.setValues(values);
						runExperiment = true;
					}
				} else {
					runExperiment = true;
				}

				if (!runExperiment) {
					setRunPressed(false);
					return;
				}

				const errors = await validate(values);
				if (!isEmpty(errors)) {
					Snackbar.error('Experiment not started, there are errors.', { toastId: 'experiment-run' });
					formik.setErrors(errors);
				} else {
					await handleRun(experimentKey, {
						redirectToEditorOnRunFailure: false,
						executionSource: 'UI_EXPERIMENT_EDITOR',
					});
				}
				setRunPressed(false);
			}}
			data-cy="run"
		>
			{runPressed ? (
				<LoadingIndicator />
			) : (
				<>
					<IconStart mr="xSmall" /> Run Experiment
				</>
			)}
		</Button>
	);
}

function useDefaultedExperimentKey(experimentKey?: string, teamId?: string): string {
	return (
		useAsyncState(async () => {
			if (experimentKey) {
				return experimentKey;
			}
			const team = teamId ? await Services.teams.findTeamById(teamId) : undefined;
			return team ? `${team.key}-##` : '';
		}, [experimentKey, teamId])[0].value || ''
	);
}
