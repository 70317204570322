/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Container, NumberIndicator, Spinner, Stack, Tooltip } from 'components';
import { getIcon, removeCategoryFromTargetDefinitionLabel } from 'targets/util';
import GroupText from 'pages/settings/components/Sidebar/GroupText';
import { localeCompareIgnoreCase, toTitleCase } from 'utils/string';
import NavItems from 'pages/settings/components/Sidebar/NavItems';
import NavItem from 'pages/settings/components/Sidebar/NavItem';
import { GeneralAdvice } from 'components/advice/AdviceBadges';
import { DataStreamResult } from 'utils/hooks/stream/result';
import ShowAdviceToggle from 'targets/ShowAdviceToggle';
import { getLabel } from 'i18n/label';
import Sidebar from 'targets/Sidebar';
import { ReactElement } from 'react';
import { Dictionary } from 'lodash';

import { TargetTypeDescriptionVO } from '../../ui-api';
import ExtensionCallout from './ExtensionCallout';

interface TableSidebarProps {
	targetsByCategory?: Dictionary<TargetTypeDescriptionVO[]>;
	targetTypesWithAdvice: DataStreamResult<string[]>;
	targetCounts?: Record<string, number>;
	activeTargetType: string;
	showAdvice: boolean;
	getTargetTypeHref: (targetType: string) => string;
	setShowAdvice: (showAdvice: boolean) => void;
}

export default function TableSidebar({
	targetTypesWithAdvice,
	targetsByCategory,
	activeTargetType,
	targetCounts,
	showAdvice,
	getTargetTypeHref,
	setShowAdvice,
}: TableSidebarProps): ReactElement {
	return (
		<Sidebar title="Targets" width={340}>
			{(collapsed) => (
				<Stack size="xSmall">
					<div style={{ margin: '32px 0 18px' }}>
						<ShowAdviceToggle
							withLegend={!collapsed}
							checked={showAdvice}
							sqashed={collapsed}
							theme="light"
							onChange={(checked) => setShowAdvice(checked)}
						/>
					</div>

					<Stack size="xLarge" mt={0}>
						{targetsByCategory && targetCounts ? (
							<Stack size="large" pb="small">
								{Object.keys(targetsByCategory)
									.sort((a, b) => localeCompareIgnoreCase(a, b))
									.map((category) => {
										return (
											<Stack key={category} size="xxxSmall" as="nav" aria-labelledby={`section-${category}`}>
												<GroupText id={`section-${category}`}>
													{collapsed ? <>&nbsp;</> : toTitleCase(category)}
												</GroupText>
												<NavItems>
													{targetsByCategory[category]?.map((targetDefinition) => {
														const label = getLabel(targetDefinition.label);
														return (
															<Tooltip
																key={targetDefinition.id}
																content={toTitleCase(label)}
																disabled={!collapsed && label.length < 26}
															>
																<Container>
																	<NavItem
																		Icon={getIcon(targetDefinition)}
																		active={activeTargetType === targetDefinition.id}
																		href={getTargetTypeHref(targetDefinition.id)}
																	>
																		<Container
																			display="flex"
																			alignItems="center"
																			justifyContent="space-between"
																			width="230px"
																		>
																			<Container
																				display="flex"
																				alignItems="center"
																				sx={{ gap: '8px', maxWidth: '100%' }}
																			>
																				{!collapsed && (
																					<Container
																						as="span"
																						sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
																					>
																						{toTitleCase(removeCategoryFromTargetDefinitionLabel(label, category))}
																					</Container>
																				)}
																				{!collapsed && targetCounts != null && (
																					<NumberIndicator
																						variant="small"
																						flexShrink={0}
																						my={2}
																						ml={collapsed ? -6 : 0}
																					>
																						{targetCounts[targetDefinition.id] ?? 0}
																					</NumberIndicator>
																				)}
																			</Container>

																			{showAdvice &&
																				!collapsed &&
																				targetTypesWithAdvice.value?.includes(targetDefinition.id) && <GeneralAdvice />}
																		</Container>
																	</NavItem>
																</Container>
															</Tooltip>
														);
													})}
												</NavItems>
											</Stack>
										);
									})}
							</Stack>
						) : (
							<Spinner variant="xLarge" color="neutral300" />
						)}
					</Stack>
					{!collapsed && (
						<Container mt="auto" mb="medium">
							<ExtensionCallout />
						</Container>
					)}
				</Stack>
			)}
		</Sidebar>
	);
}
