/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { useStableInstance } from 'utils/hooks/useStableInstance';
import { useCallback, useEffect } from 'react';
import { Services } from 'services/services';
import { useFormikContext } from 'formik';
import { VariableVO } from 'ui-api';
import { debounce } from 'lodash';

import { ExperimentFormValues } from './types';

/**
 * We want to have the backend the only source of truth for extracting variables and placeholders.
 * Since some places rely on the metadata, I put this into a separate component and enrich the form with it.
 */
export default function MetadataEnrichment(): null {
	const { values, setFieldValue } = useFormikContext<ExperimentFormValues>();

	const environmentVariablesResult = Services.environments.useEnvironmentVariables$(values.environmentId);
	const environmentVariables = environmentVariablesResult.value;

	const extractMetadata = useCallback(
		debounce(
			async (v: ExperimentFormValues, envVars: VariableVO[]) => {
				const metadata = await Services.experiments.getExperimentMetadata(v);
				setFieldValue('metadata', metadata);

				const newVariables: string[] = metadata.variables.filter(
					(used) =>
						!v.experimentVariables.find((variable) => variable.key === used) &&
						!envVars.find((variable) => variable.key === used),
				);

				setFieldValue('experimentVariables', [
					...v.experimentVariables,
					...newVariables.map((key) => ({ key, value: '' })),
				]);

				setFieldValue('variables', envVars);
			},
			500,
			{ leading: true },
		),
		[],
	);

	const [stableId] = useStableInstance<ExperimentFormValues>({
		...values,
		metadata: undefined,
		variables: [],
		experimentVariables: [],
	});
	useEffect(() => {
		if (!environmentVariables) {
			return;
		}
		extractMetadata(values, environmentVariables);
	}, [stableId, environmentVariables, extractMetadata]);

	return null;
}
