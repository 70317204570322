/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	IconBadge,
	IconCalendarClock,
	IconCalendarEdit,
	IconDelete,
	IconDuplicate,
	IconLogfile,
	IconNavigationMenuHorizontal,
	IconTemplate,
} from 'components/icons';
import {
	Button,
	ButtonIcon,
	ButtonSplit,
	ButtonSplitAction,
	ContextualMenuButton,
	Snackbar,
	Stack,
	Subnav,
	userConfirm,
} from 'components';
import {
	duplicateExperimentConfirm,
	DuplicateExperimentResult,
} from 'pages/experiments/components/DuplicateExperimentConfirm';
import { ExperimentSubHeaderEnvironments } from 'pages/experiments/components/header/experimentSubHeaderEnvironments';
import { ExperimentSubHeaderHypthesis } from 'pages/experiments/components/header/experimentSubHeaderHypthesis';
import EditExperimentScheduleModal from 'pages/experiments/components/Schedule/EditExperimentScheduleModal';
import DropdownContentFrame from 'components/Select/Dropdown/presets/components/DropdownContentFrame';
import ScheduleExperimentModal from 'pages/experiments/components/Schedule/ScheduleExperimentModal';
import ExperimentBadgeModal from 'pages/experiments/components/header/ExperimentBadgeModal';
import ExperimentFileModal from 'pages/experiments/components/header/ExperimentFileModal';
import useRefreshingSchedule from 'pages/experiments/hooks/useRefreshingSchedule';
import { prepareCopyExperimentForm } from 'pages/experiments/experiment';
import LicenseTooltipContent from 'App/components/LicenseTooltipContent';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import useGlobalPermissions from 'services/useGlobalPermissions';
import DropDown from 'components/Select/Dropdown/Dropdown';
import { Services } from 'services/services';
import { RefObject, useState } from 'react';
import { useFormikContext } from 'formik';
import { theme } from 'styles.v2/theme';
import { useHistory } from 'url/hooks';

import { saveExperiment } from './ExperimentEditorFormHandler';
import { useLicenseFeature } from '../../services/licenseApi';
import { ExperimentFormValues } from './types';
import ExperimentTags from './ExperimentTags';

export default function ExperimentSubHeader(): ReactElement {
	const { values, touched, submitForm, setValues, setTouched, isSubmitting } = useFormikContext<ExperimentFormValues>();

	const { experimentKey, name, actions } = values;

	const isNewExperiment = !experimentKey || experimentKey === '<new>';

	const isEditPermitted = actions.includes('save');
	const disabled = !isEditPermitted;

	const history = useHistory();
	const [showDownloadModal, setShowDownloadModal] = useState(false);
	const [showScheduleModal, setShowScheduleModal] = useState(false);
	const [showBadgeModal, setShowBadgeModal] = useState(false);

	const templatesEnabled = useLicenseFeature('TEMPLATES');
	const permissions = useGlobalPermissions();
	const canCreateTemplates = permissions.templates.canCreate;

	const schedule = useRefreshingSchedule(experimentKey);

	const dirty = Object.keys(touched).length > 0;
	const canSaveAsTemplate = canCreateTemplates && isEditPermitted && !isNewExperiment;
	const canSaveExperiment = canSaveAsTemplate || (isEditPermitted && (isNewExperiment || dirty));

	return (
		<Subnav
			px="medium"
			maxWidth="100%"
			sx={{ boxShadow: 'applicationSmall', zIndex: 3 }}
			stackProps={{ justifyContent: 'space-between' }}
		>
			{showDownloadModal && (
				<ExperimentFileModal
					title={`experiment-${experimentKey}`}
					experimentKey={experimentKey}
					onClose={() => setShowDownloadModal(false)}
				/>
			)}
			{showBadgeModal && (
				<ExperimentBadgeModal experimentKey={experimentKey} onClose={() => setShowBadgeModal(false)} />
			)}
			{showScheduleModal &&
				(schedule.value ? (
					<EditExperimentScheduleModal
						schedule={schedule.value}
						onClose={() => setShowScheduleModal(false)}
						disabled={disabled}
					/>
				) : (
					<ScheduleExperimentModal
						experimentKey={experimentKey}
						onClose={() => setShowScheduleModal(false)}
						disabled={disabled}
					/>
				))}

			<Stack
				justifyContent="flex-start"
				direction="horizontal"
				minWidth="fit-content"
				alignItems="center"
				overflowX="hidden"
				flexGrow={1}
			>
				<ExperimentSubHeaderEnvironments disabled={disabled} />
				<Divider />
				<ExperimentSubHeaderHypthesis disabled={disabled} />
				<Divider />
				<ExperimentTags />
			</Stack>

			<DropDown
				placement="bottom-end"
				renderComponent={({ setShowMenu, showMenu, ref }) => {
					return (
						<ButtonIcon
							variant="small"
							ref={ref as RefObject<HTMLButtonElement>}
							onClick={() => setShowMenu(!showMenu)}
							sx={{
								background: showMenu ? 'purple100' : undefined,
								border: showMenu ? '1px solid' : undefined,
								borderColor: showMenu ? 'slate' : undefined,
							}}
						>
							<IconNavigationMenuHorizontal />
						</ButtonIcon>
					);
				}}
			>
				{({ selectItem }) => {
					const buttonStyle = { minWidth: 20, minHeight: 20 };
					return (
						<DropdownContentFrame sx={{ p: 'xxSmall' }}>
							<Stack size="none">
								{!isNewExperiment && (
									<>
										<ContextualMenuButton
											onClick={() => {
												selectItem('');
												onDuplicate();
											}}
										>
											<IconDuplicate sx={buttonStyle} />
											Duplicate
										</ContextualMenuButton>
										<ContextualMenuButton
											onClick={() => {
												selectItem('');
												onDownload();
											}}
										>
											<IconLogfile sx={buttonStyle} />
											View as JSON
										</ContextualMenuButton>
										<ContextualMenuButton
											onClick={() => {
												selectItem('');
												onBadge();
											}}
										>
											<IconBadge sx={buttonStyle} />
											Create badge
										</ContextualMenuButton>
										<ContextualMenuButton
											onClick={() => {
												selectItem('');
												onSchedule();
											}}
										>
											{schedule.value ? (
												<>
													<IconCalendarEdit />
													Edit Schedule
												</>
											) : (
												<>
													<IconCalendarClock sx={buttonStyle} />
													Schedule run
												</>
											)}
										</ContextualMenuButton>
									</>
								)}

								{(actions.includes('delete') || isNewExperiment) && (
									<ContextualMenuButton
										onClick={async () => {
											if (
												(await userConfirm({
													title: isNewExperiment ? 'Discard new Experiment' : 'Delete Experiment',
													message: isNewExperiment
														? `Do you really want to discard your new Experiment ${name ?? ''}?`
														: `Do you really want to delete ${experimentKey} ${name ?? ''}?`,
													actions: [
														{ value: 'cancel', label: 'Cancel' },
														{
															value: 'confirm',
															label: isNewExperiment ? 'Discard' : `Delete ${experimentKey}`,
															variant: 'primary',
														},
													],
												})) === 'confirm'
											) {
												if (isNewExperiment) {
													history.replace('/experiments', 'redirect_after_delete');
												} else {
													try {
														await Services.experiments.deleteExperiment(experimentKey ?? '', 'UI_EDITOR');
														Snackbar.dark('Experiment deleted.', { toastId: 'experiment-deleted' });
														history.replace('/experiments', 'redirect_after_delete');
													} catch (err) {
														Snackbar.error('Experiment not deleted: ' + err.toString(), {
															toastId: 'experiment-deleted',
														});
													}
												}
											}
										}}
									>
										<IconDelete sx={buttonStyle} />
										{isNewExperiment ? 'Discard' : 'Delete '}
									</ContextualMenuButton>
								)}
							</Stack>
						</DropdownContentFrame>
					);
				}}
			</DropDown>

			<Stack direction="horizontal" justifyContent="flex-end" alignItems="center" overflowX="hidden">
				{canSaveAsTemplate ? (
					<SaveButton
						actions={[
							{
								Icon: IconTemplate,
								value: 'Save as Template',
								label: 'Save as Template',
								disabled: !templatesEnabled,
								tooltip: templatesEnabled ? undefined : <LicenseTooltipContent />,
							},
						]}
					/>
				) : canSaveExperiment ? (
					<Button variant="secondarySmall" onClick={submitForm} loading={isSubmitting} data-cy="save-experiment-button">
						Save
					</Button>
				) : null}
			</Stack>
		</Subnav>
	);

	async function ensureSaved({ saveLabel }: { saveLabel: string }): Promise<boolean> {
		if (dirty || isNewExperiment) {
			if (
				(await userConfirm({
					title: 'Unsaved Changes',
					message: `You have unsaved changes. Do you want to save ${experimentKey} ${name ?? ''}?`,
					actions: [
						{ value: 'cancel', label: 'Cancel' },
						{
							value: 'save&run',
							label: saveLabel,
							variant: 'primary',
						},
					],
				})) === 'save&run'
			) {
				const saveResult = await saveExperiment(values);
				if (saveResult == null) {
					return false;
				}
				const [, saved] = saveResult;
				if (saved === null) {
					return false;
				}

				const experimentKey = saved.experimentKey;
				const experiment = await Services.experiments.fetchExperiment(experimentKey);

				setValues({ ...values, version: experiment.version });
				setTouched({});

				return true;
			} else {
				return false;
			}
		}
		return true;
	}

	async function onDuplicate(): Promise<void> {
		const executeAction = await ensureSaved({ saveLabel: 'Save & Duplicate' });
		if (executeAction) {
			const original = await Services.experiments.fetchExperiment(experimentKey);
			const duplicateResult: DuplicateExperimentResult = await duplicateExperimentConfirm(original);
			const copyedExperimentFormData = await prepareCopyExperimentForm(
				experimentKey,
				duplicateResult,
				'UI_COPIED_EDITOR',
			);
			const saved = await Services.experiments.createOrUpdateExperiment(copyedExperimentFormData.initialValues);

			history.push(`/experiments/edit/${saved.experimentKey}/design`);
		}
	}

	async function onDownload(): Promise<void> {
		const executeAction = await ensureSaved({ saveLabel: 'Save & Download' });
		if (executeAction) {
			setShowDownloadModal(true);
		}
	}

	async function onBadge(): Promise<void> {
		const executeAction = await ensureSaved({ saveLabel: 'Save & Create Badge' });
		if (executeAction) {
			setShowBadgeModal(true);
		}
	}

	async function onSchedule(): Promise<void> {
		const executeAction = await ensureSaved({ saveLabel: 'Save & Schedule' });
		if (executeAction) {
			setShowScheduleModal(true);
		}
	}
}

function Divider(): ReactElement {
	return <div style={{ width: 1, height: 20, backgroundColor: theme.colors.neutral300 }} />;
}

interface SaveButtonProps {
	actions: ButtonSplitAction[];
}

function SaveButton({ actions }: SaveButtonProps): ReactElement | null {
	const history = useHistory();
	const { values, submitForm, isSubmitting } = useFormikContext<ExperimentFormValues>();

	return (
		<ButtonSplit
			variant="secondarySmall"
			actions={actions}
			onClick={submitForm}
			onAction={() => {
				history.push(`/settings/templates/design/fromExperiment/${values.experimentKey}`);
			}}
			loading={isSubmitting}
		>
			Save
		</ButtonSplit>
	);
}
